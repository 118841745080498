import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { CatalogsService } from '../catalogs.service';
import { PagerService } from '../pager.service';
import { DataService } from '../data.service';
import { ActivatedRoute } from '@angular/router';
import { Album } from '../album';
import { AlbumService } from '../album.service';
import { I18nGlvService } from '../i18n-glv.service';
import { formatDate } from '@angular/common';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';
import { SearchService } from '../search.service';
import { ProjectService } from '../project.service';
import { UserService } from '../user.service';
import { GenreService } from '../genre.service';
import { MusicService } from '../music.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { TrackOperationsModalComponent } from '../track-operations-modal/track-operations-modal.component';
import { DeleteTrackFromProjectComponent } from '../delete-track-from-project/delete-track-from-project.component';
import { NavbarComponent } from '../navbar/navbar.component';
import { DownloadZipMailComponent } from '../download-zip-mail/download-zip-mail.component';
import { PlayerHistoryService } from '../player-history.service';
import { Location } from '@angular/common';
import { ProjectListExistanceModalComponent } from '../project-list-existance-modal/project-list-existance-modal.component';
import * as moment from 'moment';
import { DownloadFilesService } from "../download-files.service";
import { SaveStateService } from '../save-state.service';


import { LocationStrategy } from '@angular/common';



declare var $: any;
const WaveSurfer = window['WaveSurfer'];

@Component({
  providers: [NavbarComponent],
  selector: 'app-catalogs',
  templateUrl: './catalogs.component.html',
  styleUrls: ['./catalogs.component.scss']
})

export class CatalogsComponent implements OnInit {

  @ViewChild('pageInput') pageInput: ElementRef;

  tonos = [];
  catalogs = [];
  // tracks
  countTracksSelected = 0;
  isWavesurferReady = false;
  isTrackHover = [];
  isPlayerExtras = [];
  showTrackInfo = [];
  wavesurfer = [];
  volume = 1;
  isSetIntervalDefined = null;
  laodingCatalogs = true;
  loadingAlbuns = false;
  loadingTracks = false;
  isLoop = false;
  isSelectAllTracks = false; 
  pagedTracks = [];
  trackPager: any = {};
  trackPage = 1;
  pageValue: number;
  selectedProject: any;
  wentToInit = false;

  // search
  chips = [];
  isChipPlus = true;
  moreChips = [];
  secondMoreChips = [];
  orderBy = null;
  pagedItems: any[];
  fromInsade = false;
  isAdd = false;
  isSecondAdd = false;
  anyCatalogSelected = false;
  anyAlbunSelected = false;
  selectedCatalog: any;
  selectedAlbum: any;
  albuns = [];
  selectedCatalogPosition = 1;
  selectedAlbumPosition = 1;
  isCatalogsFilter = false;
  showCatalogsDDL = false;
  tracks = [];
  projects: any[];
  itensPerPage = null;
  downlaodOneTrackAsZip: any;
  labels = [];
  arrayCol1 = [];
  arrayCol2 = [];
  arrayCol3 = [];
  arrayCol4 = [];
  arrayCol5 = [];
  arrayCol1_960 = [];
  arrayCol2_960 = [];
  arrayCol3_960 = [];
  arrayCol1_640 = [];
  arrayCol2_640 = [];
  arrayCol1_320 = [];
  numbersArray = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  downloadFormats = [];
  trackOperationsDialog: MatDialogRef<TrackOperationsModalComponent>;
  deleteTrackDialog: MatDialogRef<DeleteTrackFromProjectComponent>;
  downloadZipMailComponent: MatDialogRef<DownloadZipMailComponent>;
  projectListExistanceModalComponent: MatDialogRef<ProjectListExistanceModalComponent>;
  isAddRemoveLine = false;
  showAlbunsDDL = false;
  albumRef = null;
  prefDownloadLabel = '';
  showTrackRelatedOrSimilar = [];
  showTrackSimilar = [];
  showAlbumTracks = [];
  modalOpen = false;
  isResult = false;
  lastFocusInput = "row-one";
  tracksResults = 0;
  prevXhrRequest = null;

  searchDropdownItems = [
    {
      text: 'ANY FIELD',
      value: ''
    },
    {
      text: this.i18n.getTranslationsBD('keywords'),
      value: 'Keywords'
    },
    {
      text: this.i18n.getTranslationsBD('album-number'),
      value: 'AlbumID'
    },
    {
      text: this.i18n.getTranslationsBD('album-title'),
      value: 'AlbumTitle'
    },
    {
      text: this.i18n.getTranslationsBD('track-title'),
      value: 'TrackTitle'
    },
    {
      text: this.i18n.getTranslationsBD('author'),
      value: 'Autor'
    },
    {
      text: this.i18n.getTranslationsBD('lyrics'),
      value: 'Lyrics'
    },
    {
      text: this.i18n.getTranslationsBD('artist-vimúsica'),
      value: 'Artist'
    },
    {
      text: this.i18n.getTranslationsBD('my-comments'),
      value: 'MyComments'
    },
    {
      text: this.i18n.getTranslationsBD('my-comments'),
      value: 'MyComments'
    },
    {
      text: this.i18n.getTranslationsBD('my-ratings'),
      value: 'MyRatings'
    }
  ];
  searchDropdownItem = this.searchDropdownItems[0];
  moreSearchDropdownItemsRight = [
    {
      text: 'ANY FIELD',
      value: ''
    },
    {
      text: this.i18n.getTranslationsBD('keywords'),
      value: 'Keywords'
    },
    {
      text: this.i18n.getTranslationsBD('album-number'),
      value: 'AlbumID'
    },
    {
      text: this.i18n.getTranslationsBD('album-title'),
      value: 'AlbumTitle'
    },
    {
      text: this.i18n.getTranslationsBD('track-title'),
      value: 'TrackTitle'
    },
    {
      text: this.i18n.getTranslationsBD('author'),
      value: 'Autor'
    },
    {
      text: this.i18n.getTranslationsBD('lyrics'),
      value: 'Lyrics'
    },
    {
      text: this.i18n.getTranslationsBD('artist-vimúsica'),
      value: 'Artist'
    },
    {
      text: this.i18n.getTranslationsBD('my-comments'),
      value: 'MyComments'
    },
    {
      text: this.i18n.getTranslationsBD('my-comments'),
      value: 'MyComments'
    },
    {
      text: this.i18n.getTranslationsBD('my-ratings'),
      value: 'MyRatings'
    }
  ];
  moreSearchDropdownItemRight = this.moreSearchDropdownItemsRight[0];
  moreSearchDropdownItemLeft = 'NOT';
  moreSearchDropdownItemsLeft = [
    'NOT',
    'AND',
    'OR'
  ];
  secondMoreSearchDropdownItemsRight = [
    {
      text: 'ANY FIELD',
      value: ''
    },
    {
      text: this.i18n.getTranslationsBD('keywords'),
      value: 'Keywords'
    },
    {
      text: this.i18n.getTranslationsBD('album-number'),
      value: 'AlbumID'
    },
    {
      text: this.i18n.getTranslationsBD('album-title'),
      value: 'AlbumTitle'
    },
    {
      text: this.i18n.getTranslationsBD('track-title'),
      value: 'TrackTitle'
    },
    {
      text: this.i18n.getTranslationsBD('author'),
      value: 'Autor'
    },
    {
      text: this.i18n.getTranslationsBD('lyrics'),
      value: 'Lyrics'
    },
    {
      text: this.i18n.getTranslationsBD('artist-vimúsica'),
      value: 'Artist'
    },
    {
      text: this.i18n.getTranslationsBD('my-comments'),
      value: 'MyComments'
    },
    {
      text: this.i18n.getTranslationsBD('my-comments'),
      value: 'MyComments'
    },
    {
      text: this.i18n.getTranslationsBD('my-ratings'),
      value: 'MyRatings'
    }
  ];
  secondMoreSearchDropdownItemRight = this.secondMoreSearchDropdownItemsRight[0];
  secondMoreSearchDropdownItemLeft = 'NOT';
  secondMoreSearchDropdownItemsLeft = [
    'NOT',
    'AND',
    'OR'
  ];
  secondaryInputs = [];
  findMusicaSubscribeCansel = null;

  // advanced search
  showCountryDDL = false;
  countries = [];
  selectedCountryPosition = 0;
  showFocusDDL = false;
  focus = [];
  selectedFocusPosition = 0;
  showProducersDDL = false;
  producers = [];
  selectedProducerPosition = 0;
  showReleasesDDL = false;
  releases = [
    {
      label: this.i18n.getTranslationsBD('allcatalogues'),
      value: '',
      isVisible: true
    },
    {
      label: this.i18n.getTranslationsBD('new-releases'), //laranjas
      value: 'new_releases',
      isVisible: true
    },
    {
      label: this.i18n.getTranslationsBD('newcatalog'), //negritos
      value: 'new_catalogs',
      isVisible: true
    },
    {
      label: this.i18n.getTranslationsBD('news'), //negritos e laranjas
      value: 'new',
      isVisible: true
    }
  ];
  selectedReleasePosition = 0;

  tempStringForDDL = "";
  timeoutDDLsearch = null;
  lastSearch = null;
  indexGlobal = null;
  globalWavesurfer = null;
  clickTimeOut = null;

  loopOptions = 'all_manual';
  showMenuMobile = [];

  selectedProjectsTracks: any;
  wasDBL= false;

  loadingTracksSecondary = false;
  actualURL = window.location.href;
  constructor(public ProjectListExistanceModalComponent: MatDialog, public authService: AuthService,
    private catalogsService: CatalogsService, private searchService: SearchService, public i18n: I18nGlvService, 
    private pagerService: PagerService, private albumService: AlbumService, private dataService: DataService,
    private projectService: ProjectService, public TrackOperationsModalComponent: MatDialog, public DeleteTrackFromProjectComponent: MatDialog,
    private genreService: GenreService, private musicService: MusicService, private userService: UserService,
    private navbar: NavbarComponent, private route: ActivatedRoute, public DownloadZipMailComponent: MatDialog,
    public location: Location, public playerHistory: PlayerHistoryService, public router: Router,
    public saveState: SaveStateService, public downloadService: DownloadFilesService, private locationStrategy: LocationStrategy) {
    if (window['globalWavesurfer'] !== undefined) {
      this.globalWavesurfer = window['globalWavesurfer'];
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setWaveformHeight();
    this.checkOpenDDLs();
  }

  checkOpenDDLs(){
    if (window.matchMedia('(min-width: 320px)').matches && !window.matchMedia('(min-width: 640px)').matches) {
      $('.show').removeClass('show');
    } if (window.matchMedia('(min-width: 640px)').matches) {
      this.showMenuMobile = [];
    }
  }

  globalKeys(event) {
    event.stopPropagation();
    if (event.code == 'Space') {
      const focused = document.activeElement;
      if (!$("input").is(":focus") && !$("textarea").is(":focus") && !$(focused).is('[contenteditable="true"]')) {
        event.preventDefault();
      }
    }
    if (!this.showCatalogsDDL && !this.showAlbunsDDL) {
      if (!event.ctrlKey && !event.shiftKey && !event.altKey) {
        if ($("#row-one") != null && !this.modalOpen && this.router.url.includes('/catalogos')) {
          const focused = document.activeElement;
          if (!$("input").is(":focus") && !$("textarea").is(":focus") && !$(focused).is('[contenteditable="true"]')) {
            const lockedKeys = ["ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft", "Enter", " ", "Backspace"];
            if (!lockedKeys.includes(event.key)) {
              event.stopPropagation();
              const input: HTMLInputElement = document.getElementById('row-one') as HTMLInputElement;
              input.focus();
            }
          }
        }
      }
    } else if (this.showCatalogsDDL && !this.showAlbunsDDL) {
      const isValid = this.isKeyValid(event.keyCode);
      if (isValid) {
        this.tempStringForDDL += event.key;
        this.tempSearchDDL('catalog');
      } else {
        if (event.key === 'ArrowUp') {
          event.stopPropagation();

          this.selectedCatalogPosition--;
          if (this.selectedCatalogPosition < 0) {
            this.selectedCatalogPosition = 0;
          }
          const idToGo = "catalogItem-" + this.selectedCatalogPosition;
          const offset = $('#' + idToGo).offset();
          if (offset === undefined) {
            return;
          }
          $('#catalogsDDL').scrollTop(0);
          const positionTop = $('#' + idToGo).offset().top - 285;
          $('.albunDDL-unit').css("background", "#f2f2f2");
          $('.albunDDL-unit h3').css("color", "#676767");

          $('#' + idToGo).css("background", "#676767");
          $('#' + idToGo + ' h3').css("color", "#f2f2f2");
          let top = positionTop - ($("#catalogsDDL").height() / 2) + $(".albunDDL-unit").height();
          if (top < 0) {
            top = 0;
          }
          $('#catalogsDDL').scrollTop(top);

        } else if (event.key === 'ArrowDown') {
          this.selectedCatalogPosition++;
          if (this.selectedCatalogPosition > this.catalogs.length - 1) {
            this.selectedCatalogPosition = this.catalogs.length - 1;
          }
          const idToGo = "catalogItem-" + this.selectedCatalogPosition;
          const offset = $('#' + idToGo).offset();
          if (offset === undefined) {
            return;
          }
          $('#catalogsDDL').scrollTop(0);
          const positionTop = $('#' + idToGo).offset().top - 285;
          $('.albunDDL-unit').css("background", "#f2f2f2");
          $('.albunDDL-unit h3').css("color", "#676767");

          $('#' + idToGo).css("background", "#676767");
          $('#' + idToGo + ' h3').css("color", "#f2f2f2");
          let top = positionTop - ($("#catalogsDDL").height() / 2) + $(".albunDDL-unit").height();
          if (top < 0) {
            top = 0;
          }
          $('#catalogsDDL').scrollTop(top);
        } else if (event.key == 'Enter') {
          this.showCatalogsDDL = false;
          this.selectCatalog(this.catalogs[this.selectedCatalogPosition], this.selectedCatalogPosition);
        }
      }
    } else if (!this.showCatalogsDDL && this.showAlbunsDDL) {
      const isValid = this.isKeyValid(event.keyCode);
      if (isValid) {
        this.tempStringForDDL += event.key;
        this.tempSearchDDL('album');
      } else {
        if (event.key === 'ArrowUp') {
          this.selectedAlbumPosition--;
          if (this.selectedAlbumPosition < 0) {
            this.selectedAlbumPosition = 0;
          }
          const idToGo = "albumItem-" + this.selectedAlbumPosition;
          const offset = $('#' + idToGo).offset();
          if (offset === undefined) {
            return;
          }
          $('#albunsDDL').scrollTop(0);
          const positionTop = $('#' + idToGo).offset().top - 285;
          $('.albunDDL-unit').css("background", "#f2f2f2");
          $('.albunDDL-unit h3').css("color", "#676767");

          $('#' + idToGo).css("background", "#676767");
          $('#' + idToGo + ' h3').css("color", "#f2f2f2");
          let top = positionTop - ($("#albunsDDL").height() / 2) + $(".albunDDL-unit").height();
          if (top < 0) {
            top = 0;
          }
          $('#albunsDDL').scrollTop(top);

        } else if (event.key === 'ArrowDown') {
          this.selectedAlbumPosition++;
          if (this.selectedAlbumPosition > this.catalogs.length - 1) {
            this.selectedAlbumPosition = this.catalogs.length - 1;
          }
          const idToGo = "albumItem-" + this.selectedAlbumPosition;
          const offset = $('#' + idToGo).offset();
          if (offset === undefined) {
            return;
          }
          $('#albunsDDL').scrollTop(0);
          const positionTop = $('#' + idToGo).offset().top - 285;
          $('.albunDDL-unit').css("background", "#f2f2f2");
          $('.albunDDL-unit h3').css("color", "#676767");

          $('#' + idToGo).css("background", "#676767");
          $('#' + idToGo + ' h3').css("color", "#f2f2f2");
          let top = positionTop - ($("#albunsDDL").height() / 2) + $(".albunDDL-unit").height();
          if (top < 0) {
            top = 0;
          }
          $('#albunsDDL').scrollTop(top);
        } else if (event.key == 'Enter') {
          this.showAlbunsDDL = false;
          this.selectAlbum(this.albuns[this.selectedAlbumPosition], this.selectedAlbumPosition);
        }
      }
    }
  }

  tempSearchDDL(where) {
    if (this.timeoutDDLsearch != null) {
      clearTimeout(this.timeoutDDLsearch);
    }
    if (where == 'catalog') {
      for (let id = 0; id < this.catalogs.length; id++) {
        const catalog = this.catalogs[id];
        if (catalog.Catalog.toUpperCase().startsWith(this.tempStringForDDL.toUpperCase())) {
          const idToGo = "catalogItem-" + id;
          if (idToGo != this.lastSearch) {
            const offset = $('#' + idToGo).offset();
            if (offset === undefined) {
              return;
            }
            this.selectedCatalogPosition = id;
            $('#catalogsDDL').scrollTop(0);
            const positionTop = $('#' + idToGo).offset().top - 285;
            $('.albunDDL-unit').css("background", "#f2f2f2");
            $('.albunDDL-unit h3').css("color", "#676767");

            $('#' + idToGo).css("background", "#676767");
            $('#' + idToGo + ' h3').css("color", "#f2f2f2");
            let top = positionTop - ($("#catalogsDDL").height() / 2) + $(".albunDDL-unit").height();
            if (top < 0) {
              top = 0;
            }
            $('#catalogsDDL').scrollTop(top);
          }
          this.lastSearch = idToGo;
          break;
        }
      }
    } else if (where == 'album') {
      for (let id = 0; id < this.albuns.length; id++) {
        const album = this.albuns[id];
        const string = (album.Reference + " " + album.ReferenceTitle).toUpperCase();
        if (string.startsWith(this.tempStringForDDL.toUpperCase())) {
          const idToGo = "albumItem-" + id;
          if (idToGo != this.lastSearch) {
            const offset = $('#' + idToGo).offset();
            if (offset === undefined) {
              return;
            }
            this.selectedAlbumPosition = id;
            $('#albunsDDL').scrollTop(0);
            const positionTop = $('#' + idToGo).offset().top - 285;
            $('.albunDDL-unit').css("background", "#f2f2f2");
            $('.albunDDL-unit h3').css("color", "#676767");

            $('#' + idToGo).css("background", "#676767");
            $('#' + idToGo + ' h3').css("color", "#f2f2f2");
            let top = positionTop - ($("#albunsDDL").height() / 2) + $(".albunDDL-unit").height();
            if (top < 0) {
              top = 0;
            }
            $('#albunsDDL').scrollTop(top);
          }
          this.lastSearch = idToGo;
          break;
        }
      }
    }
    this.timeoutDDLsearch = setTimeout(() => {
      this.tempStringForDDL = "";
      this.lastSearch = null;
    }, 1000);
  }

  isKeyValid(keycode) {
    let valid =
      (keycode > 47 && keycode < 58) || // number keys
      keycode == 32 || // spacebar & return key(s) (if you want to allow carriage returns)
      (keycode > 64 && keycode < 91) || // letter keys
      (keycode > 95 && keycode < 112); // numpad keys

    return valid;
  }

  setWaveformHeight() {
	  if (!this.tracks) {return;}
    for (let i = 0, len = this.tracks.length; i < len; i++) {
      if (this.wavesurfer[i]) {
        const windowWidth = $(window).width() + 17;
        if (windowWidth < 1440) {
          this.wavesurfer[i].setHeight(29);
        } else {
          this.wavesurfer[i].setHeight(56);
        }
      }
    }
  }

  // advanced search methods
  // countries
  selectCountry(country, index) {
    this.selectedCountryPosition = index;
    this.search(null);
  }

  iterateCountries(direction, event) {
    event.stopPropagation();
    if (direction == 'up') {
      this.selectedCountryPosition--;
      if (this.selectedCountryPosition < 0) {
        this.selectedCountryPosition = this.countries.length - 1;
        return;
      }
    } else {
      this.selectedCountryPosition++;
      if (this.selectedCountryPosition > this.countries.length - 1) {
        this.selectedCountryPosition = 0;
        return;
      }
    }
    this.search(null);
  }

  // focus
  selectFocus(foco, index) {
    this.selectedFocusPosition = index;
    this.search(null);
  }

  iterateFocus(direction, event) {
    event.stopPropagation();
    if (direction == 'up') {
      this.selectedFocusPosition--;
      if (this.selectedFocusPosition < 0) {
        this.selectedFocusPosition = this.focus.length - 1;
        return;
      }
    } else {
      this.selectedFocusPosition++;
      if (this.selectedFocusPosition > this.focus.length - 1) {
        this.selectedFocusPosition = 0;
        return;
      }
    }
    this.search(null);
  }

  // producers
  selectProducer(producer, index) {
    this.selectedProducerPosition = index;
    this.search(null);
  }

  iterateProducers(direction, event) {
    event.stopPropagation();
    if (direction == 'up') {
      this.selectedProducerPosition--;
      if (this.selectedProducerPosition < 0) {
        this.selectedProducerPosition = this.producers.length - 1;
        return;
      }
    } else {
      this.selectedProducerPosition++;
      if (this.selectedProducerPosition > this.producers.length - 1) {
        this.selectedProducerPosition = 0;
        return;
      }
    }
    this.search(null);
  }

  // releases
  selectRelease(release, index) {
    this.selectedReleasePosition = index;
    this.search(null);
  }

  iterateRelease(direction, event) {
    event.stopPropagation();
    if (direction == 'up') {
      this.selectedReleasePosition--;
      if (this.selectedReleasePosition < 0) {
        this.selectedReleasePosition = this.releases.length - 1;
        return;
      }
    } else {
      this.selectedReleasePosition++;
      if (this.selectedReleasePosition > this.releases.length - 1) {
        this.selectedReleasePosition = 0;
        return;
      }
    }
    this.search(null);
  }

  //end of advanced search
  removeTracksFromProject(event, i, track) {
    if(event != undefined && event != null && event.ctrlKey){
      if(i != null){
        this.selectTrack(null, i);
        return;
      }
    }
    if(event != undefined && event != null && event.shiftKey){ 
      if(i != null){
        this.checkTracksToSelect(this.getRealIndex(this.pagedTracks[i]));
        return;
      }
    }
    const tracksToDelete = [];
    if (track != null) {
      const obj = {
        Context1J: track.Context1J,
        Context1K: track.Context1K,
        Track: track.Track,
        MetaDataID: track.id
      };
      tracksToDelete.push(obj);
    } else {
      for (let i = 0; i < this.playerHistory.selectedSongsCATALOGS.length; i++) {
        const obj = {
          Context1J: this.playerHistory.selectedSongsCATALOGS[i].Context1J,
          Context1K: this.playerHistory.selectedSongsCATALOGS[i].Context1K,
          Track: this.playerHistory.selectedSongsCATALOGS[i]['Track'],
          MetaDataID: this.playerHistory.selectedSongsCATALOGS[i].id
        };
        tracksToDelete.push(obj);
      }
    }
    this.selectedProject = this.dataService.getSelectedProject();
    this.modalOpen = true;
    this.deleteTrackDialog = this.DeleteTrackFromProjectComponent.open(DeleteTrackFromProjectComponent, { disableClose: true });
    let instance = this.deleteTrackDialog.componentInstance;
    instance.projectID = this.selectedProject.ProjectsID;
    instance.tracksToDelete = tracksToDelete;
    this.deleteTrackDialog.afterClosed().subscribe((result) => {
      this.navbar.justGetProjects();
      this.modalOpen = false;
    });
  }

  ngOnInit() {
	  
    this.wentToInit = true;
    this.checkURL(1);
    this.dataService.updateIsResult(false);
    this.isResult = false;
    this.downlaodOneTrackAsZip = this.authService.user.Pref_DownloadSingleTrackZip;
    this.itensPerPage = this.authService.user.Pref_PageResults;
    this.getGenres();
    this.getAdvancedSearchOptions();
    this.downloadFormats = this.dataService.getDownloadFormats();
    this.selectedProjectsTracks = this.dataService.selectedProjectsTracks();
    this.dataService.getSelectedProjectTracks().subscribe(data => {
      this.selectedProjectsTracks = data;
    });
    this.dataService.setLastTrackSelectedCatalog(null);
    if (this.downloadFormats == undefined) {
      this.userService.getPreferedDownload(this.authService.user.id).subscribe(downloads => {
        this.dataService.updateDownloadFormats(downloads);
        this.downloadFormats = this.dataService.getDownloadFormats();
        this.downloadFormats.forEach(format => {
          if (format.DownloadFormat == this.authService.user.Pref_DownloadFormat) {
            this.prefDownloadLabel = format.DownloadFormatEN;
            return;
          }
        });
      });
    }
    this.dataService.getModalOpen().subscribe(value => {
      this.modalOpen = value as boolean;
    });

    this.dataService.getPageCatalogos().subscribe(page => {
      let newPage : number = page;
      if(this.trackPager.currentPage != newPage){
        this.setTracksPage(newPage);
      }
    });
  }

  checkURL(from){
    
	  if (from==0) // backspace
	  {
  		  if (this.dataService.navFromNewReleases)
		  {
			  //'veio do backspace das new releases'
			  // this.dataService.navFromNewReleases=false;

			  let temp_catalogs = this.dataService.getInitialCatalogsGroups();
			  temp_catalogs.forEach((temp_catalog) => {
				if (temp_catalog.hasOwnProperty("Context1J")) {
				  temp_catalog.IsVisible = true;
				}
			  });
			  this.dataService.setInitialCatalogsGroups(temp_catalogs);
			  this.saveState.setLastFindCatalogsTab(null);
			  this.saveState.removelastSearchCatalogs();
			  this.dataService.updateSelectedAlbum(null);

			  this.router.navigate(["./catalogos"]);

			  return;
		  }			  
	  }
	
      let lastURL = this.saveState.getLastUrlCatalog();
      if(lastURL != undefined && lastURL != null){
        this.saveState.removelastSearchCatalogs();
        this.saveState.removeLastUrlCatalog();
        this.dataService.updateSelectedAlbum(null);
        const url = window.location.href;
        if(from == 1){
          if(url == lastURL){
            this.processURL();
          }
        } else{
          this.processURL();
        } 
      } 

  }

  processURL(){
	  // pg: 1
    const url = window.location.href;
    const string = url.split('catalogos')[1];
    if (string.trim() != '') {
      const array = string.split('/');
      if(array.length == 2){
        this.location.go('./catalogos');
        // this.router.navigate(['catalogos']);
        this.actualURL = window.location.href;
        if(this.catalogs.length == 0){
          this.catalogs = this.dataService.getInitialCatalogsGroups();
          this.resetCatalogs();
        }
        this.search(null);
      }
      else if (array.length == 3) {
        this.location.go('./catalogos/' + array[1]);
        // this.router.navigate(['catalogos/'+array[1]]);
        this.actualURL = window.location.href;
        if(this.catalogs.length == 0){
          this.catalogs = this.dataService.getInitialCatalogsGroups();
          this.resetCatalogs();
        }
        for (let it = 0; it < this.catalogs.length; it++) {
          const catalog = this.catalogs[it];
          if(catalog.Context1J == array[1]){
            this.selectedCatalog = catalog;
            this.selectedCatalogPosition = it;
            break;
          }
        }
        this.getAlbunsOfCatalog();
      }
    } else {
     
        this.location.go('./catalogos');
        this.actualURL = window.location.href;
        if(this.catalogs.length == 0){
          this.catalogs = this.dataService.getInitialCatalogsGroups();
          this.resetCatalogs();
        }
        this.selectedCatalogPosition = 1;
        this.selectedCatalog = this.catalogs[this.selectedCatalogPosition];
        this.anyCatalogSelected = false;
        this.loadingAlbuns = false;
        this.search(null);
      
    }
  }
  
  getAdvancedSearchOptions(){
    let lastOptionsRetrieved = this.dataService.getAdvCatalogsOptions();
    if(lastOptionsRetrieved == null){
      this.catalogsService.getFocus().subscribe( focuses => {
        let temp_focus = focuses['Data'];
        temp_focus.forEach(focus => {
          this.focus.push({label:focus, isVisible:true});
        });
        this.focus.unshift({label:this.i18n.getTranslationsBD('focus'), isVisible: true});
        this.catalogsService.getCountries().subscribe(countries => {
          let temp_countries = countries['Data'];
          temp_countries.forEach(country => {
            this.countries.push({label:country, isVisible:true});
          });
          this.countries.unshift({label:this.i18n.getTranslationsBD('country'), isVisible:true});
          this.catalogsService.getProducers().subscribe(producers => {
            let temp_producers = producers['Data'];
            temp_producers.forEach(producer => {
              this.producers.push({label: producer, isVisible: true});  
            });
            this.producers.unshift({label: this.i18n.getTranslationsBD('produtores'), isVisible: true});
            let data = {
              countries: this.countries,
              focus: this.focus,
              producers: this.producers 
            };
            this.dataService.setAdvCatalogsOptions(data);
            this.getCatalogs();
          });
        });
      });
    } else {
      this.countries = lastOptionsRetrieved['countries'];
      this.focus = lastOptionsRetrieved['focus'];
      this.producers = lastOptionsRetrieved['producers'];
      this.getCatalogs();
    }
  }

  getWidth(id) {
    let div = document.getElementById(id);
    let width = div.offsetWidth;
    return width + "px";
  }

  // adicionar vários albúns ao projecto - needs refactor
  addAlbumsToProject() {
    const tracksToAdd = [];
    let stringValidate = "";
    for (let id = 0; id < this.tracks.length; id++) {
      const track = this.tracks[id];
      if (track.isTrackSelected) {
        const obj = {
          Context1J: track.Context1J,
          Context1K: track.Context1K,
        };
        tracksToAdd.push(obj);
        stringValidate += track.Context1J + "_" + track.Context1K + ",";
      }
    }
    stringValidate = stringValidate.substring(0, stringValidate.length - 1);
    this.selectedProject = this.dataService.getSelectedProject();
    this.projectService.validateAlbumExistance(this.selectedProject.ProjectsID, stringValidate).subscribe(data => {
      const errorList = data.ErrorList;
      if (errorList != undefined) {
        if (errorList.hasOwnProperty('MusicExistsInOtherProjects')) {
          this.projectListExistanceModalComponent = this.ProjectListExistanceModalComponent.open(ProjectListExistanceModalComponent, { disableClose: true });
          let instance = this.projectListExistanceModalComponent.componentInstance;
          instance.projectList = data['MusicInOtherProjectsList'];
          this.dataService.updateModalOpen(true);
          this.modalOpen = true;
          this.projectListExistanceModalComponent.afterClosed().subscribe((result) => {
            this.dataService.updateModalOpen(false);
            this.modalOpen = false;
            if (result['action'] == 'continue') {
              this.projectService.addAlbunsToProject(this.selectedProject.ProjectsID, tracksToAdd).subscribe(data => {
                this.navbar.justGetProjects();
              });
            }
          });
        } else if (errorList.hasOwnProperty('MusicNotExistsInThisProject') || (errorList.hasOwnProperty('UserNotAllowed') && Object.entries(errorList).length === 1) || Object.entries(errorList).length === 0) {
          this.projectService.addAlbunsToProject(this.selectedProject.ProjectsID, tracksToAdd).subscribe(data => {
            this.navbar.justGetProjects();
          });
        } else {
          if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
        }
      } else {
        if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
      }
    });
  }
  
  // apagar vários albúns do projecto - needs refactor
  // delete albuns from project
  deleteAlbumsFromProject() {
    const tracksToDelete = [];
    for (let id = 0; id < this.tracks.length; id++) {
      const track = this.tracks[id];
      if (track.isTrackSelected) {
        const obj = {
          Context1J: track.Context1J,
          Context1K: track.Context1K,
        };
        tracksToDelete.push(obj);
      }
    }
    this.selectedProject = this.dataService.getSelectedProject();
    this.projectService.deleteAlbunsToProject(this.selectedProject.ProjectsID, tracksToDelete).subscribe(data => {
      this.navbar.justGetProjects();
    });
  }

  checkIfMusicExistsOnSelectedProject(track) {
    if (this.selectedProjectsTracks != undefined) {
      if (this.selectedProjectsTracks.some(e => e.id == track.id)) {
        track.isProjectIconEnabeled = true;
        return true;
      } else {
        track.isProjectIconEnabeled = false;
        return false;
      }
    } else {
      return false;
    }
    // if( !this.loadingSelectedTracksProjects ){
    //     if (this.selectedProjectsTracks.some(e => e.id == track.id)) {
    //         track.isProjectIconEnabeled = true;
    //         return true;
    //     } else {
    //         track.isProjectIconEnabeled = false;
    //         return false;
    //     }
    // } else {
    //     track.isProjectIconEnabeled = false;
    //     return false;
    // }
    // if(this.selectedProjectsTracks != undefined){
    //     if (this.selectedProjectsTracks.some(e => e.id == track.id)) {
    //         track.isProjectIconEnabeled = true;
    //         return true;
    //     } else {
    //         track.isProjectIconEnabeled = false;
    //         return false;
    //     }
    // } else{
    //     return false;
    // }
  }

  getGenres(): void {
    let already_genres = this.saveState.getInitialGenres();
    if(already_genres != null){
      const tempGenres = already_genres;
      this.tonos = this.getGenresByLetter(tempGenres['Q']);
    } else {
      this.genreService.getGenres().subscribe(genres => {
        const tempGenres = this.processGeneres(genres);
        this.tonos = this.getGenresByLetter(tempGenres['Q']);
      });
    }
  }

  processGeneres(genres) {
    const temp_genres = {
      A: [],
      C: [],
      D: [],
      E: [],
      F: [],
      J: [],
      Q: []
    };
    genres.forEach(genre => {
      const obj = {
        name: genre.Ctg,
        context: genre.Ctx,
        subCategory: []
      };
      genre.SCtg.forEach(subCat => {
        obj.subCategory.push({ name: subCat.Ctg, contextName: '', context: subCat.SCtx });
      });

      if (genre.Tp == 'A') {
        temp_genres.A.push(obj);
      } else if (genre.Tp == 'C') {
        temp_genres.C.push(obj);
      } else if (genre.Tp == 'D') {
        temp_genres.D.push(obj);
      } else if (genre.Tp == 'E') {
        temp_genres.E.push(obj);
      } else if (genre.Tp == 'F') {
        temp_genres.F.push(obj);
      } else if (genre.Tp == 'J') {
        temp_genres.J.push(obj);
      } else if (genre.Tp == 'Q') {
        temp_genres.Q.push(obj);
      }
    });
    return temp_genres;
  }

  getGenresByLetter(_genres): any[] {
    const lista = [];
    for (const key in _genres) {
      if (_genres.hasOwnProperty(key)) {
        const element = _genres[key];
        const subgenres = [];
        for (const key2 in element.subCategory) {
          if (element.subCategory.hasOwnProperty(key2)) {
            const subelement = element.subCategory[key2];
            subgenres.push({ 'name': subelement.name, 'context': subelement.context });
          }
        }
        if (subgenres.length === 0) {
          lista.push({ 'name': element.name, 'context': element.context, 'containerId': 0 });
        } else {
          lista.push({ 'name': element.name, 'context': element.context, 'containerId': 0, 'subgenres': subgenres });
        }
      }
    }
    return lista;
  }

  getCatalogs() {
	  
    if(this.dataService.getInitialCatalogsGroups().length == 0){
      this.catalogsService.getCatalogs().subscribe(catalogs => {
        this.catalogs = catalogs;
		this.catalogs.forEach((catalog, index) => {
			//catalog.cover = environment.baseUrl + 'image/catalog/?trackCatalog=' +
            //catalog.Catalog.replace(new RegExp(' & ', 'g'), '').replace('+','~').replace(new RegExp(' //', 'g'), '_').replace('+','~') +
            //'&token=' + this.authService.user.token + '&username=' + this.authService.user.username;
          catalog.position = index + 1;
          catalog.IsVisible = true;
        }); 
        this.catalogs.unshift({ Catalog: this.i18n.getTranslationsBD('catalogues') });
        this.dataService.setInitialCatalogsGroups(this.catalogs);
        this.checkSearchState('refresh'); 
      });
    } else {
      this.catalogs = this.dataService.getInitialCatalogsGroups();
      this.checkSearchState('tab');
    }

  }
  
  fscrollTop() {
	if (this.dataService.navFromNewReleases)
	{
		var doc = document.documentElement;
		doc.scrollTop = this.dataService.scrollTopNewReleases;
	}  
	return "";
  }
  

  checkSearchState(from){
    let lastSearch_obj = this.saveState.getLastSearchChipsCatalogs();
    if(lastSearch_obj != undefined && lastSearch_obj != null){
      setTimeout(() => {
        if(lastSearch_obj != undefined && lastSearch_obj != null){
          const url = window.location.href;
          const string = url.split('catalogos')[1];
          if (string.trim() !== "") {
            this.checkPreSelectedAlbumRef();
            return;
          }
          if(lastSearch_obj.lvl == 1){
            let lastCats = this.saveState.getLastFindCatalogsTab();
            var temp_chips = lastSearch_obj.chips.split(",");
            temp_chips.forEach(chip => {
              var text = chip.replace(new RegExp(' ', 'g'), '_');
              this.addChip(text, null);
            });
            if(lastSearch_obj.advanced && from == 'tab'){
              this.isAddRemoveLine = true;
              this.selectedCountryPosition = lastSearch_obj.country;
              this.selectedProducerPosition = lastSearch_obj.producer;
              this.selectedFocusPosition = lastSearch_obj.focus;
              this.selectedReleasePosition = lastSearch_obj.releases;
            }
            if(lastCats != undefined && lastCats != null){
              this.catalogs = lastCats;
              let count = 0;
              this.catalogs.forEach(cat => {
                if(cat.IsVisible){
                  count++;
                }
              });
              this.drawCats(count);
            } else {
              this.search(null);
            }
          }
          else if(lastSearch_obj.lvl == 2){ 
            this.location.go('./catalogos/' + lastSearch_obj.catalog.Context1J);
            // this.router.navigate(['catalogos/'+lastSearch_obj.catalog.Context1J]);
            this.actualURL = window.location.href;
            this.selectedCatalogPosition = lastSearch_obj.index;
            this.selectedCatalog = lastSearch_obj.catalog;
            this.laodingCatalogs = false;
            this.anyCatalogSelected = true;
            if(lastSearch_obj.chips.trim() != ""){            
              let temp_chips = lastSearch_obj.chips.split(",");
              temp_chips.forEach(chip => {
                var text = chip.replace(new RegExp(' ', 'g'), '_');
                this.addChip(text, null);
              });
            }
            this.getAlbunsOfCatalog(1);
          } else if(lastSearch_obj.lvl == 3){
            this.isAddRemoveLine = false;
            this.selectedCatalogPosition = lastSearch_obj.catalog_index;
            this.selectedCatalog = lastSearch_obj.catalog;
            this.anyCatalogSelected = true;
            this.loadingAlbuns = true;
            this.albumService.getAlbunsOfCatalog(this.selectedCatalog.Context1J).subscribe(albuns => {
              this.albuns = albuns;
              const albumPlaceholder = {
                Reference: '',
                ReferenceTitle: this.i18n.getTranslationsBD('references'),
                ReferenceDescription: ''
              };
              this.albuns.unshift(albumPlaceholder);
              this.selectedAlbumPosition = lastSearch_obj.album_index;
              this.selectedAlbum = lastSearch_obj.album;
              this.location.go('./catalogos/' + this.selectedCatalog.Context1J + '/' + this.selectedAlbum.Context1K);
              // this.router.navigate(['catalogos/'+this.selectedCatalog.Context1J + '/' + this.selectedAlbum.Context1K]);
              this.actualURL = window.location.href;
              this.loadingAlbuns = false;
              this.laodingCatalogs = false;
              this.selectAlbun(this.selectedAlbum, true);
              this.dataService.updateSelectedAlbum(null);
              if(lastSearch_obj.chips.trim() != ""){            
                let temp_chips = lastSearch_obj.chips.split(",");
                temp_chips.forEach(chip => {
                  var text = chip.replace(new RegExp(' ', 'g'), '_');
                  this.addChip(text, null);
                });
                this.search(null);
              }
            });
          }
        } else {
          this.drawCats(this.catalogs.length);
        }
      }, 1);
    } else {
      this.checkPreSelectedAlbumRef();
    }
  }

  checkPreSelectedAlbumRef(){
	  // PG 2:
    this.albumRef = this.dataService.getSelectedAlbum();
    if (this.albumRef == null) {
      const url = window.location.href;
      var string = url.split('catalogos')[1];
	  if (this.dataService.navFromNewReleases)
	  {
		  //string='';
		  //this.dataService.navFromNewReleases=false;
	  }
      if (string.trim() == "") {
        this.laodingCatalogs = false;
        this.drawCats(this.catalogs.length);
      } else {
        const array = string.split('/');
        if (array.length == 2) {
          this.catalogs.forEach((catalog, index) => {
            if (catalog.Context1J == array[1]) {
              this.selectedCatalog = catalog;
              this.selectedCatalogPosition = index;
              return;
            }
          });
          this.laodingCatalogs = false;
          this.anyCatalogSelected = true;
          let lastSearch_obj = this.saveState.getLastSearchChipsCatalogs();
          if(lastSearch_obj != undefined &&  lastSearch_obj != null){
            if(lastSearch_obj.chips.trim() != ""){            
              let temp_chips = lastSearch_obj.chips.split(",");
              temp_chips.forEach(chip => {
                var text = chip.replace(new RegExp(' ', 'g'), '_');
                this.addChip(text, null);
              });
              this.getAlbunsOfCatalog(1);
            }else{
              this.getAlbunsOfCatalog();
            }
          } else {
            let searchObj = {
              lvl: 2,
              chips:"",
              catalog: this.selectedCatalog,
              index: this.selectedCatalogPosition
            };
            this.saveState.setLastSearchChipsCatalogs(searchObj);
            this.getAlbunsOfCatalog();
          }
        } else if (array.length == 3) {
            this.isAddRemoveLine = false;
            let lastSearch_obj = this.saveState.getLastSearchChipsCatalogs();
            if(lastSearch_obj != undefined &&  lastSearch_obj != null){
              this.selectedCatalogPosition = lastSearch_obj.catalog_index;
              this.selectedCatalog = lastSearch_obj.catalog;
              this.anyCatalogSelected = true;
              this.loadingAlbuns = true;
              this.albumService.getAlbunsOfCatalog(this.selectedCatalog.Context1J).subscribe(albuns => {
                this.albuns = albuns;
                const albumPlaceholder = {
                  Reference: '',
                  ReferenceTitle: this.i18n.getTranslationsBD('references'),
                  ReferenceDescription: ''
                };
                this.albuns.unshift(albumPlaceholder);
                this.selectedAlbumPosition = lastSearch_obj.album_index;
                this.selectedAlbum = lastSearch_obj.album;
                this.location.go('./catalogos/' + this.selectedCatalog.Context1J + '/' + this.selectedAlbum.Context1K);
                // this.router.navigate(['catalogos/'+this.selectedCatalog.Context1J + '/' + this.selectedAlbum.Context1K]);
                this.actualURL = window.location.href;
                this.loadingAlbuns = false;
                this.laodingCatalogs = false;
                this.selectAlbun(this.selectedAlbum, true);
                this.dataService.updateSelectedAlbum(null);
                if(lastSearch_obj.chips.trim() != ""){            
                  let temp_chips = lastSearch_obj.chips.split(",");
                  temp_chips.forEach(chip => {
                    var text = chip.replace(new RegExp(' ', 'g'), '_');
                    this.addChip(text, null);
                  });
                  this.search(null);
                }
              });
            } else {
              this.catalogs.forEach((catalog, index) => {
                if (catalog.Context1J == array[1]) {
                  this.selectedCatalog = catalog;
                  this.selectedCatalogPosition = index;
                  return;
                }
              });
			  if (this.selectedCatalog==undefined) {return;}
              this.albumService.getAlbunsOfCatalog(this.selectedCatalog.Context1J).subscribe(albuns =>{
                this.albuns = albuns;
                const albumPlaceholder = {
                  Reference: '',
                  ReferenceTitle: this.i18n.getTranslationsBD('references'),
                  ReferenceDescription: ''
                };
                this.albuns.unshift(albumPlaceholder);
                for (let j = 0; j < this.albuns.length; j++) {
                  const album = this.albuns[j];
                  if (album.Context1K == array[2]) {
                    this.selectedAlbumPosition = j;
                    this.selectedAlbum = this.albuns[this.selectedAlbumPosition];
                    break;
                  }
                }
                let searchObj = {
                  lvl: 3,
                  chips:"",
                  catalog: this.selectedCatalog,
                  catalog_index: this.selectedCatalogPosition,
                  album: this.selectedAlbum, 
                  album_index:this.selectedAlbumPosition
                };
                this.saveState.setLastSearchChipsCatalogs(searchObj);
                this.location.go('./catalogos/' + this.selectedCatalog.Context1J + '/' + this.selectedAlbum.Context1K);
                // this.router.navigate(['catalogos/'+this.selectedCatalog.Context1J + '/' + this.selectedAlbum.Context1K]);
                this.actualURL = window.location.href;
                this.loadingAlbuns = false;
                this.laodingCatalogs = false;
                this.anyCatalogSelected = true;
                this.selectAlbun(this.selectedAlbum);
                this.dataService.updateSelectedAlbum(null);
              });
            }
        }
      }
    } else {
		// PG:
      for (let i = 0; i < this.catalogs.length; i++) {
        const element = this.catalogs[i];
        if (element.Catalog == this.albumRef.catalog) {
          this.selectedCatalog = element;
          this.selectedCatalogPosition = i;
          break;
        }
      }
      this.albumService.getAlbunsOfCatalog(this.selectedCatalog.Context1J).subscribe(albuns => {
        this.albuns = albuns;
        const albumPlaceholder = {
          Reference: '',
          ReferenceTitle: this.i18n.getTranslationsBD('references'),
          ReferenceDescription: ''
        };
        this.albuns.unshift(albumPlaceholder);
        for (let j = 0; j < this.albuns.length; j++) {
          const album = this.albuns[j];
          if (album.Reference == this.albumRef.reference) {
            this.selectedAlbumPosition = j;
            this.selectedAlbum = this.albuns[this.selectedAlbumPosition];
            break;
          }
        }
        let searchObj = {
          lvl: 3,
          chips:"",
          catalog: this.selectedCatalog,
          catalog_index: this.selectedCatalogPosition,
          album: this.selectedAlbum, 
          album_index:this.selectedAlbumPosition
        };
        this.saveState.setLastSearchChipsCatalogs(searchObj);
        this.location.go('./catalogos/' + this.selectedCatalog.Context1J + '/' + this.selectedAlbum.Context1K);
        // this.router.navigate(['catalogos/'+this.selectedCatalog.Context1J + '/' + this.selectedAlbum.Context1K]);
        this.actualURL = window.location.href;
        this.loadingAlbuns = false;
        this.laodingCatalogs = false;
        this.anyCatalogSelected = true;
        this.selectAlbun(this.selectedAlbum);
        this.dataService.updateSelectedAlbum(null);
      });
    }
  }

  selectCatalog(catalog, index) {
    this.isAddRemoveLine = false;
    $("html, body").animate({ scrollTop: 0 }, "slow");
    if (index == 0) {
      this.selectedCatalogPosition = 1;
      this.selectedCatalog = this.catalogs[this.selectedCatalogPosition];
      this.anyCatalogSelected = false;
      this.loadingAlbuns = false;
      this.location.go('./catalogos');
      // this.router.navigate(['catalogos']);
      this.actualURL = window.location.href;
    } else {
      this.selectedCatalogPosition = index;
      this.selectedCatalog = catalog;
      this.anyCatalogSelected = true;
      this.loadingAlbuns = true;
      this.selectedAlbumPosition = 0;
      this.anyAlbunSelected = false;
      this.wavesurfer.map(function (x) {
        if (x !== undefined) {
          x.pause();
          x.destroy();
        }
        return x;
      });
     
      let searchObj = {
        lvl: 2,
        chips:"",
        catalog: this.selectedCatalog,
        index: this.selectedCatalogPosition
      };
      this.saveState.setLastSearchChipsCatalogs(searchObj);
      this.saveState.setLastFindCatalogsTab(this.catalogs);
      this.dataService.updateIsResult(false);
      this.isResult = false;
      this.location.go('./catalogos/' + this.selectedCatalog.Context1J);
      // this.router.navigate(['catalogos/'+this.selectedCatalog.Context1J]);
      this.actualURL = window.location.href;
      this.getAlbunsOfCatalog();
    }
  }

  selectAlbum(album, index, searchAfter = false) {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    this.anyCatalogSelected = true;
    this.selectedAlbumPosition = index;
    this.selectedAlbum = album;
    this.selectAlbun(album, searchAfter);
    this.dataService.updateIsResult(false);
    this.isResult = false;
    this.chips = [];
    this.secondaryInputs = [];
    $("#row-one").val('');

    let searchObj = {
      lvl: 3,
      chips:"",
      catalog: this.selectedCatalog,
      catalog_index: this.selectedCatalogPosition,
      album: this.selectedAlbum, 
      album_index:this.selectedAlbumPosition
    };
    this.saveState.setLastSearchChipsCatalogs(searchObj);
    this.saveState.setLastFindCatalogsTab(this.catalogs);
    this.dataService.setLastTrackSelectedCatalog(null);
  }
  
  	fchars(s: string): string {
		s = s.replace(" & ", "");
		s = s.replace("&", "");
		s = s.replace(/ /g, "_");
		//alert(s);
		return s;
	}

getAlbunsOfCatalog(proced = 0) {
  if(proced == 0){
    this.chips =[];
    this.secondaryInputs = [];
    $("#row-one").val('');
  }

  // $$$
  let selectedCatalog = null;
  this.catalogs.forEach((catalog, index) => {
    if (selectedCatalog == null && catalog.Catalog == this.selectedCatalog.Catalog)
    {
      catalog.cover = environment.baseUrl + 'image/catalog/?trackCatalog=' +
        catalog.Catalog.replace(new RegExp(' & ', 'g'), '').replace('+','~').replace(new RegExp(' ', 'g'), '_').replace('+','~') +
        '&token=' + this.authService.user.token + '&username=' + this.authService.user.username;
      catalog.position = index + 1;

      selectedCatalog = catalog;
    }
  });

  this.albumService.getAlbunsOfCatalog(this.selectedCatalog.Context1J).subscribe(albuns => {
    this.albuns = albuns;
    const albumPlaceholder = {
      Reference: '',
      ReferenceTitle: this.i18n.getTranslationsBD('references'),
      ReferenceDescription: ''
    };
    this.albuns.unshift(albumPlaceholder);
    this.selectedAlbumPosition = 0;
    this.selectedAlbum = this.albuns[this.selectedAlbumPosition];
    this.loadingAlbuns = false;
    this.albuns.forEach((albun, index) => {
      if (index > 0 && index < 99911) {
        albun.IsVisible = false;
        albun.cover = environment.baseUrl + 'image/album/' +
          this.selectedCatalog.Catalog.replace(new RegExp(' & ', 'g'), '').replace('+','~').replace(new RegExp(' ', 'g'), '_').replace('+','~') +
          '/' + albun.Reference.replace(new RegExp(' & ', 'g'), '').replace(new RegExp(' ', 'g'), '_') +
          '/?&token=' + this.authService.user.token + '&username=' + this.authService.user.username;
      }
    });

    this.showImages(selectedCatalog); // Agora, as imagens vão ser carregadas uma a uma
    if(proced == 1){
      this.search(null);
    }
  });
}

async showImages(selectedCatalog) {
  for (let index = 0; index <= this.albuns.length-1; index++) {
    if (index == 0) { 
      selectedCatalog.IsVisible = true;
    } else { 
      const albun = this.albuns[index];
      // Carregar a imagem e esperar o evento onload
      await this.loadImage(albun.cover);
      albun.IsVisible = true;
    }

    // Atraso opcional entre as imagens (caso necessário)
    //await this.delay(1000); //  de atraso entre cada imagem
  }
}

// Função para carregar a imagem e esperar o evento onload
loadImage(imageSrc: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageSrc;
    img.onload = () => {
      resolve();
    };
    img.onerror = (error) => {
      //reject(`Erro ao carregar a imagem: ${imageSrc}`);
	  //alert(`Erro ao carregar a imagem: ${imageSrc}`);
	   resolve();
    };
  });
}

// Função para criar o atraso
delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

  iterateAlbuns(direction, event) {
    this.dataService.updateIsResult(false);
    this.isResult = false;
    event.stopPropagation();
    if (direction == 'up') {
      this.selectedAlbumPosition--;
      if (this.selectedAlbumPosition < 0) {
        this.selectedAlbumPosition = this.albuns.length - 1;
      }
    } else {
      this.selectedAlbumPosition++;
      if (this.selectedAlbumPosition > this.albuns.length - 1) {
        this.selectedAlbumPosition = 0;
      }
    }
    this.selectedAlbum = this.albuns[this.selectedAlbumPosition];
    this.selectAlbum(this.selectedAlbum, this.selectedAlbumPosition);
  }

  iterateCatalogs(direction, event) {
    this.dataService.updateIsResult(false);
    this.isResult = false;
    event.stopPropagation();
    this.showCatalogsDDL = false;
    if (direction == 'up') {
      this.selectedCatalogPosition--;
      if (this.selectedCatalogPosition == 0) {
        this.selectedCatalogPosition = 1;
        this.selectedCatalog = this.catalogs[this.selectedCatalogPosition];
        this.anyCatalogSelected = false;
        this.loadingAlbuns = false;
        this.location.go('./catalogos');
        // this.router.navigate(['catalogos']);
        this.actualURL = window.location.href;
        return;
      }
    } else {
      this.selectedCatalogPosition++;
      if (this.selectedCatalogPosition > this.catalogs.length - 1) {
        this.selectedCatalogPosition = 1;
        this.selectedCatalog = this.catalogs[this.selectedCatalogPosition];
        this.anyCatalogSelected = false;
        this.loadingAlbuns = false;
        this.location.go('./catalogos');
        // this.router.navigate(['catalogos']);
        this.actualURL = window.location.href;
        return;
      }
    }

    this.selectedCatalog = this.catalogs[this.selectedCatalogPosition];
    this.selectCatalog(this.selectedCatalog, this.selectedCatalogPosition);
    this.getAlbunsOfCatalog();
  }

  selectAlbun(albun, searchAfter = false) {
    if (albun.Reference != "") {
      this.dataService.updateIsResult(false);
      this.location.go('./catalogos/' + this.selectedCatalog.Context1J + '/' + this.selectedAlbum.Context1K);
      // this.router.navigate(['catalogos/' + this.selectedCatalog.Context1J + '/' + this.selectedAlbum.Context1K]);
      this.actualURL = window.location.href;
      this.isResult = false;
      this.selectedAlbum = albun;
      this.anyAlbunSelected = true;
      const context1J = this.selectedAlbum.Context1J;
      const context1K = this.selectedAlbum.Context1K;
      this.loadingTracks = true;
      this.albumService.getTracksOfAlbun(context1J, context1K).subscribe(tracks => {
        
        const listMusica = this.musicService.mountMusicList(tracks, this.tonos, false, false, false, false);
        this.tracks = listMusica;
        this.loadingTracks = false;
        for (let it = 0; it < this.tracks.length; it++) {
          const track = this.tracks[it];
          track.everything = tracks[it].Everything;
        }

        this.tracksResults = this.tracks.length;
        this.trackPager.totalPages = undefined;
        this.isWavesurferReady = true;
        this.dataService.updateIsResult(true);
        this.isResult = true;
        
        this.tracks.forEach(track => {
          track.isTrackSelected = false;
          track.IsVisible = true;
          this.playerHistory.selectedSongsCATALOGS.forEach(selectedTrack => {
            if (track.id == selectedTrack.id) {
              track.isTrackSelected = true;
            }
          });
        });
        this.setTracksPage(1);
        if(searchAfter){
          this.search(null);
        } else {
          this.loadingTracks = false;
        }
      });
    } else {
      this.location.go('./catalogos/' + this.selectedCatalog.Context1J);
      // this.router.navigate(['catalogos/' + this.selectedCatalog.Context1J]);
      this.actualURL = window.location.href;
      this.selectedAlbumPosition = 0;
      this.anyAlbunSelected = false;
      if (!this.albuns[1].hasOwnProperty('cover')) {
        this.getAlbunsOfCatalog();
      }
    }
  }

  getMusicTono(musica) {
    if (musica.Context1Q != null && musica.Context1Q != '') {
      for (let i = 0; i < this.tonos.length; i++) {
        if (musica.Context1Q == this.tonos[i].context) {
          musica.tono = this.tonos[i].name;
          return musica;
        }
      }
    } else if (musica.Contexts != null && musica.Contexts != '') {
      let contextArray = musica.Contexts.split(" ");
      for (let i = 0; i < this.tonos.length; i++) {
        if (contextArray.includes(this.tonos[i].context)) {
          musica.Context1Q = this.tonos[i].context;
          musica.tono = this.tonos[i].name;
          return musica;
        }
      }
    } else if (musica.KeyCode != null && musica.KeyCode != '') {
      for (let i = 0; i < this.tonos.length; i++) {
        if (this.tonos[i].name.charAt(0) == musica.KeyCode) {
          musica.Context1Q = this.tonos[i].context;
          musica.tono = this.tonos[i].name;
          return musica;
        }
      }
    } else {
      musica.Context1Q = null;
      musica.tono = null;
      return musica;
    }
    return musica;
  }

  // ADD TO PROJECTS
  // add album to selected project (line by line)
  addAlbumToProject(track, event, i) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      this.checkTracksToSelect(this.getRealIndex(this.pagedTracks[i]));
      return;
    }
    let tracksToAdd = [];
    let musicList = "";
    for (let id = 0; id < this.tracks.length; id++) {
      let obj = {
        Context1J: this.tracks[id].Context1J,
        Context1K: this.tracks[id].Context1K,
        Track: this.tracks[id].track,
        TrackTitle: this.tracks[id].name,
        TrackTime: this.tracks[id].duration,
        Description: this.tracks[id].description
      }
      tracksToAdd.push(obj);
      musicList += this.tracks[id].id + ",";
    }
    musicList = musicList.substring(0, musicList.length - 1);
    const type = this.authService.user.Pref_DownloadFormat;
    this.selectedProject = this.dataService.getSelectedProject();
    if (this.prevXhrRequest != null) {
      this.prevXhrRequest.unsubscribe();
      this.prevXhrRequest = null;
    }


    this.prevXhrRequest =
      this.searchService.validateDownload(this.selectedProject.ProjectsID, musicList, type).subscribe(data => {
        const errorList = data.ErrorList;
        if (errorList != undefined) {
          if (errorList.hasOwnProperty('MusicExistsInOtherProjects')) {
            this.projectListExistanceModalComponent = this.ProjectListExistanceModalComponent.open(ProjectListExistanceModalComponent, { disableClose: true });
            let instance = this.projectListExistanceModalComponent.componentInstance;
            instance.projectList = data['MusicInOtherProjectsList'];
            this.dataService.updateModalOpen(true);
            this.modalOpen = true;
            this.projectListExistanceModalComponent.afterClosed().subscribe((result) => {
              this.dataService.updateModalOpen(false);
              this.modalOpen = false;
              if (result['action'] == 'continue') {
                this.projectService.setMultipleTracksToProject(tracksToAdd, this.selectedProject.ProjectsID).subscribe(result => {
                  this.navbar.justGetProjects();
                });
              }
            });
          } else if (errorList.hasOwnProperty('MusicNotExistsInThisProject') || (errorList.hasOwnProperty('UserNotAllowed') && Object.entries(errorList).length === 1) || Object.entries(errorList).length === 0) {
            this.projectService.setMultipleTracksToProject(tracksToAdd, this.selectedProject.ProjectsID).subscribe(result => {
              this.navbar.justGetProjects();
            });
          } else {
            if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
          }
        } else {
          if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
        }
        this.prevXhrRequest = null;
      });


    // this.projectService.setMultipleTracksToProject(tracksToAdd, this.selectedProject.ProjectsID).subscribe(result => {
    //     this.navbar.justGetProjects();
    // });
  }

  //delete album from Projects
  deleteAlbumFromProject(track, event, i) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      this.checkTracksToSelect(this.getRealIndex(this.pagedTracks[i]));
      return;
    }
    const tracksToDelete = [];
    for (let a = 0; a < this.tracks.length; a++) {
      const obj = {
        Context1J: this.tracks[a].Context1J,
        Context1K: this.tracks[a].Context1K,
        Track: this.tracks[a].Track
      };
      tracksToDelete.push(obj);
    }
    this.selectedProject = this.dataService.getSelectedProject();
    const projectId = this.selectedProject.ProjectsID;
    this.deleteTrackDialog = this.DeleteTrackFromProjectComponent.open(DeleteTrackFromProjectComponent, { disableClose: true });
    let instance = this.deleteTrackDialog.componentInstance;
    instance.projectID = projectId;
    instance.tracksToDelete = tracksToDelete;
    this.modalOpen = true;
    this.deleteTrackDialog.afterClosed().subscribe((result) => {
      this.modalOpen = false;
      if (result.message == 'success') {
        this.navbar.justGetProjects();
      }
    });
  }

  // add track to selected project (line by line)
  addToProjects(event, i, track): void {
    event.stopPropagation();
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      this.checkTracksToSelect(this.getRealIndex(this.pagedTracks[i]));
      return;
    }
    if (!track.isProjectIconEnabeled) {
      this.selectedProject = this.dataService.getSelectedProject();
      const normalizedData = {
        'ProjectsID': this.selectedProject.ProjectsID,
        'Context1J': track.Context1J,
        'Context1K': track.Context1K,
        'Track': track.track,
        'TrackTitle': track.name,
        'TrackTime': track.duration,
        'Description': ''
      };

      const musicList = track.id;
      const type = this.authService.user.Pref_DownloadFormat;
      const projectId = this.selectedProject.ProjectsID;

      if (this.prevXhrRequest != null) {
        this.prevXhrRequest.unsubscribe();
        this.prevXhrRequest = null;
      }

      this.prevXhrRequest =
        this.searchService.validateDownload(projectId, musicList, type).subscribe(data => {
          const errorList = data.ErrorList;
          const url = environment.baseUrl + 'music/download/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + this.authService.user.token;
          if (errorList != undefined) {
            if (errorList.hasOwnProperty('MusicExistsInOtherProjects')) {
              this.projectListExistanceModalComponent = this.ProjectListExistanceModalComponent.open(ProjectListExistanceModalComponent, { disableClose: true });
              let instance = this.projectListExistanceModalComponent.componentInstance;
              instance.projectList = data['MusicInOtherProjectsList'];
              this.dataService.updateModalOpen(true);
              this.modalOpen = true;
              this.projectListExistanceModalComponent.afterClosed().subscribe((result) => {
                this.dataService.updateModalOpen(false);
                this.modalOpen = false;
                if (result['action'] == 'continue') {
                  this.projectService.setTrackToProject(normalizedData).subscribe(() => {
                    this.navbar.justGetProjects();
                  });
                }
              });
            } else if (errorList.hasOwnProperty('MusicNotExistsInThisProject') || (errorList.hasOwnProperty('UserNotAllowed') && Object.entries(errorList).length === 1) || Object.entries(errorList).length === 0) {
              this.projectService.setTrackToProject(normalizedData).subscribe(() => {
                this.navbar.justGetProjects();
              });
            }
            else {
              if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
            }
          } else {
            if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
          }
          this.prevXhrRequest = null;
        });
    }
  }

  // add one track to project, opens modal (line-by-line ) - removed
  addOneTrackToProject(track) {
    let tracksToAdd = [];

    let obj = {
      Context1J: track.Context1J,
      Context1K: track.Context1K,
      Track: track.track,
      TrackTitle: track.name,
      TrackTime: track.duration,
      Description: track.description,
      isTrackSelected: true,
      metadataID: track.id
    }
    tracksToAdd.push(obj);
    this.projects = this.dataService.getProjects();
    this.trackOperationsDialog = this.TrackOperationsModalComponent.open(TrackOperationsModalComponent, { disableClose: true });
    let instance = this.trackOperationsDialog.componentInstance;
    instance.tracksToOperate = tracksToAdd;
    instance.projects = this.projects;
    instance.projectID = null;
    instance.mode = "playlists";
    this.modalOpen = true;
    this.trackOperationsDialog.afterClosed().subscribe((result) => {
      let success = false;
      if (result.action == 'move sucess') {
        this.ngOnInit();
        this.countTracksSelected = 0;
      } else if (result.action == 'copy sucess') {
        this.ngOnInit();
        this.countTracksSelected = 0;
      }
      this.navbar.justGetProjects();
      this.modalOpen = false;
    });
  }

  //add multiple tracks to selected project
  addmultipleTracksToSelectedProject(event) {
    event.stopPropagation();
    let musicList = "";
    const tracksToAdd = [];
    this.selectedProject = this.dataService.getSelectedProject();
    for (let idx = 0; idx < this.playerHistory.selectedSongsCATALOGS.length; idx++) {
      const obj = {
        Context1J: this.playerHistory.selectedSongsCATALOGS[idx].Context1J,
        Context1K: this.playerHistory.selectedSongsCATALOGS[idx].Context1K,
        Track: this.playerHistory.selectedSongsCATALOGS[idx].track,
        TrackTitle: this.playerHistory.selectedSongsCATALOGS[idx].name,
        TrackTime: this.playerHistory.selectedSongsCATALOGS[idx].duration,
        Description: this.playerHistory.selectedSongsCATALOGS[idx].description,
        isTrackSelected: true,
        metadataID: this.playerHistory.selectedSongsCATALOGS[idx].id
      };
      tracksToAdd.push(obj);
      musicList += this.playerHistory.selectedSongsCATALOGS[idx].id + ",";
    }
    musicList = musicList.substring(0, musicList.length - 1);
    const type = this.authService.user.Pref_DownloadFormat;
    const projectId = this.selectedProject.ProjectsID;
    if (this.prevXhrRequest != null) {
      this.prevXhrRequest.unsubscribe();
      this.prevXhrRequest = null;
    }

    this.prevXhrRequest =
      this.searchService.validateDownload(projectId, musicList, type).subscribe(data => {

        const errorList = data.ErrorList;
        const url = environment.baseUrl + 'music/download/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + this.authService.user.token;
        if (errorList != undefined) {
          if (errorList.hasOwnProperty('MusicExistsInOtherProjects')) {
            this.projectListExistanceModalComponent = this.ProjectListExistanceModalComponent.open(ProjectListExistanceModalComponent, { disableClose: true });
            let instance = this.projectListExistanceModalComponent.componentInstance;
            instance.projectList = data['MusicInOtherProjectsList'];
            this.dataService.updateModalOpen(true);
            this.modalOpen = true;
            this.projectListExistanceModalComponent.afterClosed().subscribe((result) => {
              this.dataService.updateModalOpen(false);
              this.modalOpen = false;
              if (result['action'] == 'continue') {
                this.projectService.setMultipleTracksToProject(tracksToAdd, this.selectedProject.ProjectsID).subscribe(result => {
                  this.navbar.justGetProjects();
                });
              }
            });
          } else if (errorList.hasOwnProperty('MusicNotExistsInThisProject') || (errorList.hasOwnProperty('UserNotAllowed') && Object.entries(errorList).length === 1) || Object.entries(errorList).length === 0) {
            this.projectService.setMultipleTracksToProject(tracksToAdd, this.selectedProject.ProjectsID).subscribe(result => {
              this.navbar.justGetProjects();
            });
          }
          else {
            if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
          }
        } else {
          if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
        }
        this.prevXhrRequest = null;
      });
  }

  // add multiple tracks to project, opens modal (results bar) - removed
  addmultipleTracksToProject(type) {

    let tracksToAdd = [];
    for (let id = 0; id < this.tracks.length; id++) {
      if (this.tracks[id].isTrackSelected) {
        let obj = {
          Context1J: this.tracks[id].Context1J,
          Context1K: this.tracks[id].Context1K,
          Track: this.tracks[id].track,
          TrackTitle: this.tracks[id].name,
          TrackTime: this.tracks[id].duration,
          Description: this.tracks[id].description,
          isTrackSelected: true,
          metadataID: this.tracks[id].id
        }
        tracksToAdd.push(obj);
      }
    }
    this.projects = this.dataService.getProjects();
    this.trackOperationsDialog = this.TrackOperationsModalComponent.open(TrackOperationsModalComponent, { disableClose: true });
    let instance = this.trackOperationsDialog.componentInstance;
    instance.tracksToOperate = tracksToAdd;
    instance.projects = this.projects;
    instance.projectID = null;
    instance.mode = "playlists";
    this.modalOpen = true;
    this.trackOperationsDialog.afterClosed().subscribe((result) => {
      let success = false;
      this.modalOpen = false;
      if (result.action == 'move sucess') {
        this.ngOnInit();
        this.countTracksSelected = 0;
      } else if (result.action == 'copy sucess') {
        this.ngOnInit();
        this.countTracksSelected = 0;
      }
      this.navbar.justGetProjects();
    });

  }

  selectTrack($event, index, state = null) {
    if($event != undefined && $event != null){
      $event.stopPropagation();
      if($event.shiftKey){ 
        this.checkTracksToSelect(this.getRealIndex(this.pagedTracks[index]));
        return;
      }
    }
    let count = 0;
    let realIndex =((this.trackPager.currentPage - 1) * this.trackPager.pageSize) + index;
    
    if(state != null){
      this.tracks[realIndex].isTrackSelected = state;
    }else {
      this.tracks[realIndex].isTrackSelected = !this.tracks[realIndex].isTrackSelected;
    }
    if (this.tracks[realIndex].isTrackSelected) {
      this.playerHistory.addSongToSelectedCATALOGS(this.tracks[realIndex]);
      const id = this.tracks[realIndex].id;
      this.tracks.forEach(element => {
        if (element.id == id) {
          element.isTrackSelected = true;
          this.dataService.setLastTrackSelectedCatalog(this.getRealIndex(element));
        }
      });
    } else {
      this.playerHistory.removeSongFromSelectedCATALOGS(this.tracks[realIndex]);
      const id = this.tracks[realIndex].id;
      this.tracks.forEach(element => {
        if (element.id == id) {
          element.isTrackSelected = false;
        }
      });
    }
  }

  selectAllTracks(tracks) {
    let areAlreadySelected = true;
    let firsTrackOfPage = (this.pageValue - 1) * this.itensPerPage;
    let lastTrackOfPage = this.pagedTracks.length - 1;

    for (let i = 0; i < tracks.length; i++) {
      const element = tracks[i];
      if (i >= firsTrackOfPage && i <= lastTrackOfPage) {
        if (!element.isTrackSelected) {
          areAlreadySelected = false;
        }
      }
    }

    if (areAlreadySelected) {
      for (let i = 0; i < tracks.length; i++) {
        const element = tracks[i];
        if (i >= firsTrackOfPage && i <= lastTrackOfPage) {
          element.isTrackSelected = false;
          // this.countTracksSelected--;
          this.playerHistory.removeSongFromSelectedCATALOGS(element);
        }
      }
    } else {
      for (let i = 0; i < tracks.length; i++) {
        const element = tracks[i];
        if (i >= firsTrackOfPage && i <= lastTrackOfPage) {
          element.isTrackSelected = true;
          // this.countTracksSelected++;
          this.playerHistory.addSongToSelectedCATALOGS(element);
        }
      }
    }
  }

  deselectAllTracks() {
    this.tracks.map(function (x) {
      x.isTrackSelected = false;
      return x;
    });
    this.countTracksSelected = 0;
    // this.isSelectAllTracks = false;
    this.playerHistory.addSongToSelectedCATALOGS(null);
  }

  removeExtraSounds(page) {
    for (let i = this.tracks.length - 1; i >= 0; i--) {
      if (('isRelated' in this.tracks[i]) || ('isSimilar' in this.tracks[i]) || ('isFromAlbum' in this.tracks[i])) {
        this.tracks.splice(i, 1);
        this.wavesurfer.splice(i, 1);
      }
      this.showTrackSimilar[i] = false;
      this.showTrackRelatedOrSimilar[i] = false;
      this.showAlbumTracks[i] = false;
      this.showTrackInfo[i] = false;
    }
    this.setTracksPage(parseInt(page, 10));
  }

  setTracksPage(page: number, action = null) {
    if (page < 1 || (page > this.trackPager.totalPages && this.trackPager.totalPages != 0) || isNaN(page)) {
      return;
    }

    this.indexGlobal = null;
    const _self =this;

    if (this.tracks) {
      let tracks_visivies = [];
      this.tracks.forEach(track => {
        if(track.IsVisible)
        {
          tracks_visivies.push(track);
        }
      });
      this.tracksResults = tracks_visivies.length;
      this.trackPager = this.pagerService.getPager(tracks_visivies.length, page, this.itensPerPage);
      this.pagedTracks = tracks_visivies.slice(this.trackPager.startIndex, this.trackPager.endIndex + 1);
    }
    this.goToTrack(1);
    this.pageValue = +this.trackPager.currentPage;
    if (action != null && action == 'next') {
      this.goToTrack(1);
      const obj = {
        track: this.pagedTracks[0],
        i: this.getRealIndex(this.pagedTracks[0]),
        tracks: this.getVisibleTracks(this.tracks),
        from: 'catalogs',
        search: this.selectedAlbum.Context1J+"/"+this.selectedAlbum.Context1K
      };
      this.indexGlobal = 0;
      this.dataService.updateTrackSelected(obj);
      this.pagedTracks[0].isPlaying = true;
    } else if (action != null && action == 'previous') {
      this.goToTrack(this.pagedTracks.length);
      const obj = {
        track: this.pagedTracks[this.pagedTracks.length - 1],
        i: this.getRealIndex(this.pagedTracks[this.pagedTracks.length - 1]),
        tracks: this.getVisibleTracks(this.tracks),
      };
      this.indexGlobal = this.pagedTracks.length - 1;
      this.dataService.updateTrackSelected(obj);
      this.pagedTracks[this.indexGlobal].isPlaying = true;
    }
    this.isWavesurferReady = true;
    this.showTrackInfo = [];
    this.updateMusics();
  }

  updateMusics(){
    this.tracks.forEach((track, it) => {
      let containerIt = it;
        if(containerIt >= this.authService.user.Pref_PageResults){
          containerIt-=this.authService.user.Pref_PageResults;
        }
      track.container = '#track-' + containerIt + '-' + track.metadataID + '-progress';
      track.timeContainer = '#currentTime-track-' + containerIt + '-' + track.metadataID;
      if(this.dataService.getFPobject() != null){
        for (let index = 0; index < this.dataService.getFPobject()['tracks'].length; index++) {
          const fpTrack = this.dataService.getFPobject()['tracks'][index];
          if(fpTrack.id == track.id){
            fpTrack.container = track.container;
            fpTrack.timeContainer = track.timeContainer;
            track.currentTime = fpTrack.currentTime;
            track.currentTimeDisplay = fpTrack.currentTimeDisplay;
            break;
          } 
        }
      }
      if(this.dataService.getFPobject() != null && this.dataService.getFPobject()['track'] != null && track.id == this.dataService.getFPobject()['track'].id){
        this.dataService.getFPobject()['track'].container = track.container;
        this.dataService.getFPobject()['track'].timeContainer = track.timeContainer;
      }
      const width = track.currentTime * 100 / track.durationSecs;
      if(width != 0 && width != 100){
        track.playerWidth = width + "%";
        track.borderRight = '1px solid black';
      }
    });
  }

  newPlay(track, i, event) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      this.checkTracksToSelect(this.getRealIndex(this.pagedTracks[i]));
      return;
    }
    if (!$(event.target).hasClass('dropdown-toggle') && !$(event.target).hasClass('dropdown-item')) {
      let pi = i + ((this.trackPager.currentPage-1) * this.authService.user.Pref_PageResults);
      let obj = {
        track: track,
        i: this.getRealIndex(track),
        tracks: this.getVisibleTracks(this.tracks),
        from: 'catalogs',
        search: this.selectedAlbum.Context1J+"/"+this.selectedAlbum.Context1K
      };
      if(this.dataService.checkIfPlaying(obj.track)){
        window['globalWavesurfer'].playPause();
        return;
      }
      if (this.indexGlobal == null) {
        this.dataService.updateTrackSelected(obj);
      } else {
        if (this.indexGlobal == i) {
          window['globalWavesurfer'].playPause();
          if (window['globalWavesurfer'].isPlaying()) {
            track.isPlaying = true;
          } else {
            obj['action'] = 'pause';
            track.isPlaying = false;
          }
          this.dataService.updateTrackSelected(obj);
        } else {
          this.dataService.updateTrackSelected(obj);
        }
      }
    }
    this.indexGlobal = i;
  }

  checkTracksToSelect(i){
    this.clearSelection();  
    let last_index = this.dataService.getLastTrackSelectedCatalog();
    let initial_index = -1;
    if(last_index != null){
      initial_index = last_index; 
    }    
    if(initial_index == i){
      this.selectTrack(null, i, true);
      return;
    }
    if(initial_index > i){
      for (let it = i; it < initial_index; it++) {
        this.playerHistory.addSongToSelectedCATALOGS(this.tracks[it]);
        const id = this.tracks[it].id;
        this.tracks.forEach(element => {
          if (element.id == id) {
            element.isTrackSelected = true;
            this.dataService.setLastTrackSelectedCatalog(it);
          }
        });
      }
    } else {
      for (let it = initial_index +1; it <= i; it++) {
        this.playerHistory.addSongToSelectedCATALOGS(this.tracks[it]);
        const id = this.tracks[it].id;
        this.tracks.forEach(element => {
          if (element.id == id) {
            element.isTrackSelected = true;
            this.dataService.setLastTrackSelectedCatalog(it);
          }
        });
      }
    }
    setTimeout(() => {
      this.clearSelection();  
    }, 0);
  }

  clearSelection()
  {
   if (window.getSelection) {
     window.getSelection().removeAllRanges();
    }
  }
  
  getPositionClicked(track, i, event) {
    event.stopPropagation();
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      this.checkTracksToSelect(this.getRealIndex(this.pagedTracks[i]));
      return;
    }
    setTimeout(() => {
      if(!this.wasDBL){
        const posX = event.offsetX > 0 ? event.offsetX : event.layerX;
        const width = $('#track-' + i + '-' + track.metadataID).width();
        const percentage = posX * 100 / width;
        const duration = moment.duration('00:' + track.duration).asSeconds();
        const playFrom = percentage * duration / 100;
        track.currentTime = playFrom;
        track.currentTimeDisplay = moment('2015-01-01').startOf('day').seconds(playFrom).format('mm:ss');
        const obj = {
          track: track,
          i: this.getRealIndex(track),
          tracks: this.getVisibleTracks(this.tracks),
          from: 'catalogs',
          search: this.selectedAlbum.Context1J+"/"+this.selectedAlbum.Context1K
        };
        this.dataService.updateTrackSelected(obj);
        this.indexGlobal = i;
      }
    }, 400);
  }

  setFocusPlayer(el): void {
    return;
  }

  playNext($event, index) {
    $event.stopPropagation();
    if($event != undefined && $event != null && $event.ctrlKey){
      this.selectTrack(null, index);
      return;
    }
    if($event != undefined && $event != null && $event.shiftKey){ 
      this.checkTracksToSelect(this.getRealIndex(this.pagedTracks[index]));
      return;
    }
    const positionAbsolute = ((this.trackPager.currentPage - 1) * this.trackPager.pageSize) + index;
    if (positionAbsolute === this.tracks.length - 1) {
      return;
    }
    if (index === this.pagedTracks.length - 1) {
      this.setTracksPage(this.trackPager.currentPage + 1, 'next');
    } else {
      const next = (index + 1) % this.tracks.length;
      const obj = {
        track: this.pagedTracks[next],
        i: this.getRealIndex(this.pagedTracks[next]),
        tracks: this.getVisibleTracks(this.tracks),
        from: 'catalogs',
        search: this.selectedAlbum.Context1J+"/"+this.selectedAlbum.Context1K
      };
      this.dataService.updateTrackSelected(obj);
      this.indexGlobal = next;
      this.pagedTracks[next].isPlaying = true;
    }
  }

  playPrev($event, index) {
    $event.stopPropagation();
    if($event != undefined && $event != null && $event.ctrlKey){
      this.selectTrack(null, index);
      return;
    }
    if($event != undefined && $event != null && $event.shiftKey){ 
      this.checkTracksToSelect(this.getRealIndex(this.pagedTracks[index]));
      return;
    }
    const positionAbsolute = ((this.trackPager.currentPage - 1) * this.trackPager.pageSize) + index;
    if (positionAbsolute === 0 && this.pageValue == 1) {
      return;
    }
    if (index === 0) {
      this.setTracksPage(this.trackPager.currentPage - 1, 'previous');
    } else {
      const prev = (index - 1) % this.itensPerPage;
      const obj = {
        track: this.pagedTracks[prev],
        i: this.getRealIndex(this.pagedTracks[prev]),
        tracks: this.getVisibleTracks(this.tracks),
        from: 'catalogs',
        search: "{'catalog': "+this.selectedAlbum.Context1J+", 'album': "+this.selectedAlbum.Context1K+"}"
      };
      this.dataService.updateTrackSelected(obj);
      this.indexGlobal = prev;
      this.pagedTracks[prev].isPlaying = true;
    }
  }

  goToTrack(index) {
    setTimeout(() => {
      const offset = 110 * (index - 1);
      if (offset === undefined) {
        return;
      }
      const positionTop = offset;
      $('html, body').scrollTop(positionTop);
    }, 200);
  }

  presistCatalog() {
    if (this.showCatalogsDDL) {
      this.selectCatalog(this.catalogs[this.selectedCatalogPosition], this.selectedCatalogPosition);
      this.showCatalogsDDL = false;
    }
  }

  getVisibleTracks(tracks){
    let tempTracks = [];
    tracks.forEach(track => {
      if(track.IsVisible){
        tempTracks.push(track);
      }
    });
    return tempTracks;
  }

  skipBackward($event, index) {
    $event.stopPropagation();
    if($event != undefined && $event != null && $event.ctrlKey){
      this.selectTrack(null, index);
      return;
    }
    if($event != undefined && $event != null && $event.shiftKey){ 
      this.checkTracksToSelect(this.getRealIndex(this.pagedTracks[index]));
      return;
    }
    if (index == this.indexGlobal) {
      window['globalWavesurfer'].skipBackward(10);
    } else {
      this.tracks[index].currentTime = this.tracks[index].currentTime - 10;
      if (this.tracks[index].currentTime < 0) {
        this.tracks[index].currentTime = 0;
      }
      const obj = {
        track: this.pagedTracks[index],
        i: this.getRealIndex(this.pagedTracks[index]),
        tracks: this.getVisibleTracks(this.tracks),
        from: 'catalogs',
        search: this.selectedAlbum.Context1J+"/"+this.selectedAlbum.Context1K
      };
      this.dataService.updateTrackSelected(obj);
    }
    this.indexGlobal = index;
  }

  skipForward($event, index) {
    $event.stopPropagation();
    if($event != undefined && $event != null && $event.ctrlKey){
      this.selectTrack(null, index);
      return;
    }
    if($event != undefined && $event != null && $event.shiftKey){ 
      this.checkTracksToSelect(this.getRealIndex(this.pagedTracks[index]));
      return;
    }
    if (index == this.indexGlobal) {
      window['globalWavesurfer'].skipForward(10);
    } else {
      this.tracks[index].currentTime = this.tracks[index].currentTime + 10;

      if (this.tracks[index].currentTime > this.tracks[index].durationSecs) {
        this.tracks[index].currentTime = this.tracks[index].durationSecs;
      }
      const obj = {
        track: this.pagedTracks[index],
        i: this.getRealIndex(this.pagedTracks[index]),
        tracks: this.getVisibleTracks(this.tracks),
        from: 'catalogs',
        search: this.selectedAlbum.Context1J+"/"+this.selectedAlbum.Context1K
      };
      this.dataService.updateTrackSelected(obj);
    }
    this.indexGlobal = index;
  }

  clickOpenInfo($event, i, track) {
    $event.stopPropagation();
    if($event != undefined && $event != null && $event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if($event != undefined && $event != null && $event.shiftKey){ 
      this.checkTracksToSelect(this.getRealIndex(this.pagedTracks[i]));
      return;
    }
    let pos = i;
    this.showTrackInfo[pos] = !this.showTrackInfo[pos];
    if (this.showTrackInfo[pos]) {
      if (!('isRelated' in track) && !('isSimilar' in track) && !('isFromAlbum' in track)) {
       
        this.updateContainer();
        this.searchService.getRatingAndComments(track.metadataID).subscribe(data => {
          if (data != null) {
            track.MyComments = data['MyComments'];
            track.MyRating = data['MyRating'];
          }
        });
      } else {
        for (let j = this.pagedTracks.length - 1; j >= 0; j--) {
          if (j != i) {
            this.showTrackInfo[j] = false;
          }
        }
        track.star1Over = false;
        track.star2Over = false;
        track.star3Over = false;
        track.star4Over = false;
        track.star5Over = false;
        track.MyComments = null;
        track.MyRating = null;
        this.searchService.getRatingAndComments(track.metadataID).subscribe(data => {
          if (data != null) {
            track.MyComments = data['MyComments'];
            track.MyRating = data['MyRating'];
          }
        });
      }

    }

  }

  addCommentAndRating(track, event, i) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      this.checkTracksToSelect(this.getRealIndex(this.pagedTracks[i]));
      return;
    }
    const rating = track.MyRating != undefined ? track.MyRating : null;
    const textarea = document.getElementById('comments-' + track.guid) as HTMLTextAreaElement;
    const comments = textarea.value;
    const obj = {
      "userId": this.authService.user.id,
      "contextJ": track.Context1J,
      "contextK": track.Context1K,
      "track": track.track,
      "comment": comments,
      "rate": rating
    };

    this.searchService.setRatingAndComments(obj).subscribe(data => { });
  }

  removeRating(track, event, i) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      this.checkTracksToSelect(this.getRealIndex(this.pagedTracks[i]));
      return;
    }
    track.MyRating = "";
    const rating = track.MyRating;
    const textarea = document.getElementById('comments-' + track.guid) as HTMLTextAreaElement;
    const comments = textarea.value;
    const obj = {
      "userId": this.authService.user.id,
      "contextJ": track.Context1J,
      "contextK": track.Context1K,
      "track": track.track,
      "comment": comments,
      "rate": rating
    };
    this.searchService.setRatingAndComments(obj).subscribe(data => { });
  }

  oneDownloadToRuleThemAll(event, track, selectedType, i) {
    if (this.authService.isLoggedIn && this.authService.user.Accs_MaxDownloads === 0) {
      alert(this.i18n.getTranslationsBD('impossible-to-download'));
      return false;
    }
    if (event != null) {
      event.stopPropagation();
      if(event != undefined && event != null && event.ctrlKey){
        if(i != null){
          this.selectTrack(null, i);
          return;
        }
      }
      if(event != undefined && event != null && event.shiftKey){ 
        if(i != null){
          this.checkTracksToSelect(this.getRealIndex(this.pagedTracks[i]));
          return;
        }
      }
    }

    let musicList = this.downloadService.prepareDownloadList(track, this.playerHistory.selectedSongsCATALOGS);
    let type = this.downloadService.prepareType(selectedType);
    let projectId = this.downloadService.prepareProjectId(this.dataService.getSelectedProject());
    let emails = null;

    this.downloadService.DownloadWithValidation(projectId, type, musicList, this.navbar);
  }

  // CATALOGS
  addChip(chip, position): void {
    if (typeof chip === 'string') {
      if (chip.includes(' ')) {
        const chipstoAdd = chip.split(' ');
        const _self = this;
        chipstoAdd.map(function (x) {
          _self.addChip(x, null);
        });
        return;
      }
    }
    let _chip = null;
    if (chip.hasOwnProperty('name')) {
      _chip = chip;
      chip = chip.name;
    }
    chip = chip.trim();
    if (!chip || this.chips.length > 5) {
      return;
    }
    if (chip.charAt(0) === '-') {
      this.isChipPlus = false;
      chip = chip.substr(1);
    } else {
      this.isChipPlus = true;
    }

    chip = chip.replace(new RegExp('_', 'g'), ' ');

    const result = {
      'name': chip,
      'isChipPlus': this.isChipPlus
    };
    if (_chip !== null && _chip.hasOwnProperty('name')) {
      result['context'] = _chip.context;
    }
    if (position == null) {
      this.chips.push(result);
    } else {
      this.chips.splice(position, 0, result);
    }

    this.secondaryInputs = [];
    this.chips.forEach((chip, id) => {
      this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
    });

  }

  deleteChip(chip): void {
    this.chips = this.chips.filter(c => c !== chip);
    this.search(null);
  }

  //////////////// TESTE NAVEGAÇÃO CHIPS //////////////// 
  //1a LINHA
  chipsNavitagion(evt, side, from){
    evt.stopPropagation();
    const value = from == 'row-one' ? $("#row-one").val() : $("#secondary-input"+from).text();
    if(value.trim() != ''){
      return;
    }    
    const focused = $(':focus');
    const id = focused.attr('id');
    if(side == 'previous'){
      if(id == "row-one"){
        if(this.chips.length > 0){
          $("#secondary-input" + (this.chips.length-1)).focus();
        }
      } else if(focused.hasClass('secondary-search-input')){
        const focusedIt = Number(id.replace('secondary-input',''));
        const targetId = focusedIt - 1;
        if(targetId >= 0){
          $("#secondary-input" + targetId).focus();
        } 
      }
    } else {
      if(focused.hasClass('secondary-search-input')){
        const focusedIt = Number(id.replace('secondary-input',''));
        const targetId = focusedIt + 1;
        if(targetId < this.chips.length){
          $("#secondary-input" + targetId).focus();
        } else {
          $("#row-one").focus();
        }
      }
    }
  }

  checkIfDeleteChipInAbsolutePosition(evt, it, id){
    let val;
    if(id == 'row-one'){
      val = $("#"+id).val();
    } else {
      val = $("#"+id).text();
    }
    if(val.trim() != ''){
      return;
    }
    if(it == 0){
      return;
    }
    evt.preventDefault();
    const tempChip = this.chips[it-1];
    const content = tempChip.name;
    if(it == this.chips.length){
      this.chips.pop();
      $("#row-one").val(content);
      this.secondaryInputs = [];
      this.chips.forEach((chip, id) => {
        this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
      });
    } else {
      this.chips.splice(it-1, 1);
      setTimeout(() => {
        this.secondaryInputs = [];
        this.chips.forEach((chip, id) => {
          this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
        });
        $("#secondary-input" + (it-1)).text(content);  
        this.setCursor("secondary-input"+(it-1), content.length);
      }, 0);
    }
  }

  setCursor(id, pos) { 
    let tag = document.getElementById(id); 
    let setpos = document.createRange(); 
    let set = window.getSelection(); 
    setpos.setStart(tag.childNodes[0], pos); 
    setpos.collapse(true); 
    set.removeAllRanges();  
    set.addRange(setpos); 
    tag.focus(); 
  } 

  checkIfPreDeleteChipInAbsolutePosition(evt, it){
    const val = $("#secondary-input"+it).text();
    if(val.trim() != ''){
      return;
    }
    evt.preventDefault();
    const tempChip = this.chips[it];
    const content = tempChip.name;
    if(it == this.chips.length - 1 ){
      this.chips.pop();
      $("#row-one").val(content);
      this.setSelectionRange(document.getElementById('row-one'), 0,0);
      this.secondaryInputs = [];
      this.chips.forEach((chip, id) => {
        this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
      });
    } else {
      this.chips.splice(it, 1);
      setTimeout(() => {
        this.secondaryInputs = [];
        this.chips.forEach((chip, id) => {
          this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
        });
        $("#secondary-input" + (it)).text(content).focus();  
      }, 0);
    }
  } 

  setSelectionRange(input, selectionStart, selectionEnd) {
    if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(selectionStart, selectionEnd);
    } else if (input.createTextRange) {
      let range = input.createTextRange();
      range.collapse(true);
      range.moveEnd('character', selectionEnd);
      range.moveStart('character', selectionStart);
      range.select();
    }
  }

  checkIfChangeChip($event, side, i){
    let posI = this.getCaretPosition(document.getElementById("chip"+i));
    setTimeout(() => {
      let posF = this.getCaretPosition(document.getElementById("chip"+i));
      if(side == 'previous'){
        if(posI == 0 && posF == 0){
          $("#secondary-input"+i).focus();
        }
      } else {
        if(posI == $("#chip"+i).text().length && posF == $("#chip"+i).text().length){
         let next = i+1;
         if(document.getElementById('secondary-input'+next)!=null){
           $("#secondary-input"+next).focus();
         }else {
          $("#row-one").focus();
         }
        }
      }
      
    }, 10);
  }

  getCaretPosition(element) {
    let caretOffset = 0;
    let range = window.getSelection().getRangeAt(0);
    let preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    caretOffset = preCaretRange.toString().length;
    return caretOffset;
  }

  getCaretPos(oField) {
    if (oField.selectionStart || oField.selectionStart == '0') {
      return oField.selectionStart;
    } else {
      return 0;
    }
  }

  checkHowManyChips(id, evt){
    evt.stopPropagation();
    evt.preventDefault();
    setTimeout(() => {
      let text = $("#"+id).val();
      let carretPos = this.getCaretPos(document.getElementById(id));
      let chip1Text = text.substring(0, carretPos);
      let chip2Text = text.substring(carretPos, text.length);
      let chip1 = {name: chip1Text.replace(new RegExp('_', 'g'), ' '), isChipPlus: true}; 
      let chip2 = {name: chip2Text.replace(new RegExp('_', 'g'), ' '), isChipPlus: true};
      if(chip1Text.trim() != '' && chip2Text.trim() != ''){
        if(id == 'row-one'){
          this.chips.push(chip1);
          this.chips.push(chip2);
        }
      } else {
        if(chip1Text.trim() != '' && chip2Text.trim() == ''){
          if(id == 'row-one'){
            this.chips.push(chip1);
          }
        } else if(chip1Text.trim() == '' && chip2Text.trim() != ''){
          if(id == 'row-one'){
            this.chips.push(chip2);
          }
        }
      }
      $("#"+id).val("");
      if(id == 'row-one'){
        this.secondaryInputs = [];
        this.chips.forEach((chip, id) => {
          this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
        });
      }
    }, 0);
  }

  checkHowManyChipsAbsPos(id, i, evt, clas){
    evt.stopPropagation();
    evt.preventDefault();
    setTimeout(() => {
      let text = $("#"+id).text();
      let carretPos = this.getCaretPosition(document.getElementById(id));
      let chip1Text = text.substring(0, carretPos);
      let chip2Text = text.substring(carretPos, text.length);
      let chip1 = {name: chip1Text, isChipPlus: true}; 
      let chip2 = {name: chip2Text, isChipPlus: true};
      if(chip1Text.trim() != '' && chip2Text.trim() != ''){
        if(clas == 0){
          this.chips.splice(i, 0, chip1);
          this.chips.splice(i+1, 0, chip2);
        }
      } else {
        if(chip1Text.trim() != '' && chip2Text.trim() == ''){
          if(clas == 0){
            this.chips.splice(i, 0, chip1);
          }
        } else if(chip1Text.trim() == '' && chip2Text.trim() != ''){
          if(clas == 0){
            this.chips.splice(i, 0, chip2);
          }
        }
      }
      $("#"+id).text("");
      if(clas == 0){
        this.secondaryInputs = [];
        this.chips.forEach((chip, id) => {
          this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
        });
      }
    }, 0);
  }

  checkIfDeleteChip(chip, i) {
    setTimeout(() => {
      const texto = $("#chip" + i).text();
      if (texto.length == 0) {
        this.deleteChip(chip);
      }
    }, 10);
  }

  searchClean(): void {
    this.chips = [];
    this.secondaryInputs = [];
    $("#row-one").val('');
    this.anyCatalogSelected = false;
    this.selectedAlbumPosition = 0;
    this.selectedCatalogPosition = 1;
    this.selectedCatalog = this.catalogs[0];
    this.anyAlbunSelected = false;
    this.wavesurfer.map(function (x) {
      if (x !== undefined) {
        x.pause();
        x.destroy();
      }
      return x;
    });
    this.location.go('./catalogos');
    // this.router.navigate(['catalogos']);
    this.actualURL = window.location.href;
    this.saveState.removelastSearchCatalogs();
    this.saveState.setLastFindCatalogsTab(null);
    this.resetCatalogs();
    this.countries.forEach(country => {
      country.isVisible = true;
    });
    this.focus.forEach(focus => {
      focus.isVisible = true;
    });
    this.producers.forEach(producer => {
      producer.isVisible = true;
    });
    this.releases.forEach(release => {
      release.isVisible = true;
    });
    this.selectedCountryPosition = 0;
    this.selectedFocusPosition = 0;
    this.selectedProducerPosition = 0;
    this.selectedReleasePosition = 0;
    this.drawCats(this.catalogs.length);
    this.dataService.setLastTrackSelectedCatalog(null);
  }

  checkVisibleCatalogs(){
    var all_invisible = true;
    for (let it = 0; it < this.catalogs.length; it++) {
      const catalog = this.catalogs[it];
      if(catalog.IsVisible){
        all_invisible = false;
        break;
      }
    }
    return all_invisible;
  }

  search(event): void {
    if(event !=  null){
      if((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || (event.keyCode >= 65 && event.keyCode <= 90) || event.keyCode == 8 || event.keyCode == 46 || event.keyCode == 32 || event.keyCode == 189){
        if(!this.anyCatalogSelected){
          this.laodingCatalogs = true;
        }
        else if(this.anyCatalogSelected && !this.anyAlbunSelected){
          this.loadingAlbuns = true;
        }
        else{
          this.loadingTracks = true;
        }
      }else {
        this.laodingCatalogs = false;
        this.loadingAlbuns = false;
        this.loadingTracks = false;
        return;
      }
    } else {
      if(!this.anyCatalogSelected){
        this.laodingCatalogs = true;
      }
      else if(this.anyCatalogSelected && !this.anyAlbunSelected){
        this.loadingAlbuns = true;
      }
      else{
        this.loadingTracks = true;
      }
    }
    const rowOneElement: HTMLInputElement = document.getElementById('row-one') as HTMLInputElement;
    const rowOneInput: string = rowOneElement.value;
    const rowOneElementAfter: HTMLInputElement = document.getElementById('row-one') as HTMLInputElement;
    const rowOneInputAfter: string = rowOneElementAfter.value;
    if(rowOneInput == rowOneInputAfter){
      let texts_to_compare = [];
      this.chips.forEach(chip => {
        texts_to_compare.push(chip.name.replace(new RegExp('_', 'g'), ' '));
      });
      this.secondaryInputs.forEach(input => {
        if(input.value.trim() != ''){
          texts_to_compare.push(input.value.name.toUpperCase());
        }
      });
      if(rowOneInputAfter.trim() != ""){
        texts_to_compare.push(rowOneInputAfter.trim().replace(new RegExp('_', 'g'), ' '));
      }
      if(!this.anyCatalogSelected){  
        var count_catalogs = 0; 
        this.catalogs.shift();
        this.catalogs.forEach(catalog => {
          if(this.chips.length == 0){
            const rowOneInputVal = rowOneInput.replace(new RegExp('_', 'g'), ' ');
            if(catalog.Catalog.toUpperCase().includes(rowOneInputVal.toUpperCase())){
              catalog.IsVisible = true;
              count_catalogs++;
            }else {
              catalog.IsVisible = false;
            }
          } else {
            let has_one_of_chips = false;
            for (let it = 0; it < texts_to_compare.length; it++) {
              const text_to_compare = texts_to_compare[it];
              if(catalog.Catalog.toUpperCase().includes(text_to_compare.toUpperCase())){
                has_one_of_chips = true;
                break;
              }
            }
            if(has_one_of_chips){
              catalog.IsVisible = true;
              count_catalogs++;
            }else{
              catalog.IsVisible = false;
            }
          }
          if(this.isAddRemoveLine) { //pesquisa avançada
            const country = this.countries[this.selectedCountryPosition];
            const producer = this.producers[this.selectedProducerPosition];
            const focus = this.focus[this.selectedFocusPosition];
            const releases = this.releases[this.selectedReleasePosition];
            if(country.label != this.i18n.getTranslationsBD('country')){ //filtra por país
              if(catalog.IsVisible && catalog.Country.trim().toUpperCase() != country.label.trim().toUpperCase()){
                catalog.IsVisible = false;    
                count_catalogs--;
              }
            }
            if(focus.label != this.i18n.getTranslationsBD('focus')){ //filtra por país
              if(catalog.IsVisible && !catalog.Type.trim().toUpperCase().includes(focus.label.trim().toUpperCase())){
                catalog.IsVisible = false;    
                count_catalogs--;
              }
            }
            if(producer.label != this.i18n.getTranslationsBD('produtores')){ 
              if(catalog.IsVisible && catalog.Producer.trim().toUpperCase() != producer.label.trim().toUpperCase()){
                catalog.IsVisible = false;    
                count_catalogs--;
              }
            }
            if(releases.value != ""){ //filtra por país
              if(releases.value == 'new_catalogs'){
                if(catalog.IsVisible && !catalog.NewRelease){
                  catalog.IsVisible = false;    
                  count_catalogs--;
                }
              } else if(releases.value == 'new_releases'){
                if(catalog.IsVisible && (!catalog.UpdateRelease || catalog.NewRelease)){
                  catalog.IsVisible = false;    
                  count_catalogs--;
                }
              } else if(releases.value == 'new'){
                if(catalog.IsVisible && (!catalog.UpdateRelease && !catalog.NewRelease)){
                  catalog.IsVisible = false;    
                  count_catalogs--;
                }
              }                
            }
          }
        });

        const country = this.countries[this.selectedCountryPosition];
        const producer = this.producers[this.selectedProducerPosition];
        const focus = this.focus[this.selectedFocusPosition];
        const releases = this.releases[this.selectedReleasePosition];
        if(count_catalogs>0){
          let searchObj = {
            lvl: 1,
            chips:texts_to_compare.join(","),
            country: this.selectedCountryPosition,
            producer: this.selectedProducerPosition,
            focus: this.selectedFocusPosition,
            releases: this.selectedReleasePosition,
            advanced: this.isAddRemoveLine,
            count: count_catalogs
          };
          this.saveState.setLastSearchChipsCatalogs(searchObj);
          this.saveState.setLastFindCatalogsTab(this.catalogs);
        }
        this.drawCats(count_catalogs);
        this.catalogs.unshift({Catalog: this.i18n.getTranslationsBD('catalogues')});
      }
      else if(this.anyCatalogSelected && !this.anyAlbunSelected){
        if(texts_to_compare.length > 0){
          this.albuns.forEach(album => {
            let has_all_chips = true;
            for (let it = 0; it < texts_to_compare.length; it++) {
              const text_to_compare = texts_to_compare[it];
              if(!album.Reference.toUpperCase().includes(text_to_compare.toUpperCase()) 
                && !album.ReferenceTitle.toUpperCase().includes(text_to_compare.toUpperCase())
                && !album.ReferenceDescription.toUpperCase().includes(text_to_compare.toUpperCase())){
                  has_all_chips = false;
                break;
              }
            }
            if(has_all_chips){
              album.IsVisible = true;
            }else{
              album.IsVisible = false;
            }
          });
          let last_obj = this.saveState.getLastSearchChipsCatalogs();
          if(last_obj != undefined && last_obj != null){
            let new_obj = last_obj;
            new_obj['chips'] = texts_to_compare.join(',');
            this.saveState.setLastSearchChipsCatalogs(new_obj);
          } else {
            let searchObj = {
              lvl: 2,
              chips:texts_to_compare.join(','),
              catalog: this.selectedCatalog,
              index: this.selectedCatalogPosition
            };
            this.saveState.setLastSearchChipsCatalogs(searchObj);
          }
          
        } else {
          let searchObj = {
            lvl: 2,
            chips: "",
            catalog: this.selectedCatalog,
            index: this.selectedCatalogPosition
          };
          this.saveState.setLastSearchChipsCatalogs(searchObj);
          this.albuns.forEach(album => {
            album.IsVisible = true;
          });
        }
      }
      else {
        if(texts_to_compare.length > 0){
          this.tracks.forEach(track => {
            let has_all_chips = true;
              for (let it = 0; it < texts_to_compare.length; it++) {
                const text_to_compare = texts_to_compare[it];
                if(!track.hasOwnProperty('everything') || !track.everything.toUpperCase().includes(text_to_compare.toUpperCase())){
                  has_all_chips = false;
                  break;
                }
              }
              if(has_all_chips){
                track.IsVisible = true;
              }else{
                track.IsVisible = false;
              }
          });
          let last_obj = this.saveState.getLastSearchChipsCatalogs();
          if(last_obj != undefined &&  last_obj != null){
            let new_obj = last_obj;
            new_obj['chips'] = texts_to_compare.join(',');
            this.saveState.setLastSearchChipsCatalogs(new_obj);
          } else {
            let searchObj = {
              lvl: 3,
              chips: texts_to_compare.join(','),
              catalog: this.selectedCatalog,
              catalog_index: this.selectedCatalogPosition,
              album: this.selectedAlbum, 
              album_index:this.selectedAlbumPosition
            };
            this.saveState.setLastSearchChipsCatalogs(searchObj);
          }
        } else {
          let searchObj = {
            lvl: 3,
            chips: "",
            catalog: this.selectedCatalog,
            catalog_index: this.selectedCatalogPosition,
            album: this.selectedAlbum, 
            album_index:this.selectedAlbumPosition
          };
          this.saveState.setLastSearchChipsCatalogs(searchObj);
          this.tracks.forEach(track => {
            track.IsVisible = true;
          });
        }
        this.setTracksPage(1);
      }
      this.laodingCatalogs = false;
      this.loadingAlbuns = false;
      this.loadingTracks = false;  
    } else{
      this.laodingCatalogs = false;
      this.loadingAlbuns = false;
      this.loadingTracks = false;
    }
  }

  filterAdvancedSearchOptions(){
    this.countries.forEach((country, it) => {
      if(it != 0){
        let isCountryVisible = false;
        for (let it = 0; it < this.catalogs.length; it++) {
          const catalog = this.catalogs[it];
          if(catalog.IsVisible){
            if(catalog.Country.trim().toUpperCase() == country.label.trim().toUpperCase()){
              isCountryVisible = true;
              break;
            }
          }
        }
        country.isVisible = isCountryVisible;
      }
    });
    this.focus.forEach((focus, it) => {
      if(it != 0){
        let isFocusVisible = false;
        for (let it = 0; it < this.catalogs.length; it++) {
          const catalog = this.catalogs[it];
          if(catalog.IsVisible){
            if(!catalog.Type.trim().toUpperCase().includes(focus.label.trim().toUpperCase())){
              isFocusVisible = true;
              break;
            }
          }
        }
        focus.isVisible = isFocusVisible;
      }
    });
    this.producers.forEach((producer, it) => {
      if(it != 0){
        let isProducerVisible = false;
        for (let it = 0; it < this.catalogs.length; it++) {
          const catalog = this.catalogs[it];
          if(catalog.IsVisible && catalog.Producer){
            if(catalog.Producer.trim().toUpperCase() == producer.label.trim().toUpperCase()){
              isProducerVisible = true;
              break;
            }
          }
        }
        producer.isVisible = isProducerVisible;
      }
    });

    let isNewCatalogsVisible = false;
    let isNewReleasesVisible = false;
    let isNewsVisible = false;
    for (let it = 0; it < this.catalogs.length; it++) {
      const catalog = this.catalogs[it];
      if(catalog.IsVisible){
        if(catalog.NewRelease){
          isNewCatalogsVisible = true;
        }
        if(catalog.UpdateRelease && !catalog.NewRelease){
          isNewReleasesVisible = true;
        }
        if(catalog.UpdateRelease || catalog.NewRelease){
          isNewsVisible = true;
        }
      }
    }
    this.releases[1].isVisible = isNewReleasesVisible;
    this.releases[2].isVisible = isNewCatalogsVisible;
    this.releases[3].isVisible = isNewsVisible;
  }

  drawCats(count_catalogs){
    this.laodingCatalogs = false;
    const catalogsLength = count_catalogs;
    const colLength = Math.ceil(catalogsLength / 5);
    this.arrayCol1 = [];
    this.arrayCol2 = [];
    this.arrayCol3 = [];
    this.arrayCol4 = [];
    this.arrayCol5 = [];
    let visibleIterador = 0;
    for (let i = 0; i < this.catalogs.length; i++) {
      if(this.catalogs[i].IsVisible){
        if (visibleIterador >= 0 && visibleIterador < colLength) {
          this.arrayCol1.push(this.catalogs[i]);
          visibleIterador++;
        } else if (visibleIterador >= colLength && visibleIterador < colLength * 2) {
          this.arrayCol2.push(this.catalogs[i]);
          visibleIterador++;
        } else if (visibleIterador >= colLength * 2 && visibleIterador < colLength * 3) {
          this.arrayCol3.push(this.catalogs[i]);
          visibleIterador++;
        } else if (visibleIterador >= colLength * 3 && visibleIterador < colLength * 4) {
          this.arrayCol4.push(this.catalogs[i]);
          visibleIterador++;
        } else if (visibleIterador >= colLength * 4 && visibleIterador <= colLength * 5) {
          this.arrayCol5.push(this.catalogs[i]);
          visibleIterador++;
        }
      }
    }
    this.arrayCol1_960 = [];
    this.arrayCol2_960 = [];
    this.arrayCol3_960 = [];
    const colLength_960 = Math.ceil(catalogsLength / 3);
    visibleIterador = 0;
    for (let i = 0; i < this.catalogs.length; i++) {
      if(this.catalogs[i].IsVisible){
        if (visibleIterador >= 0 && visibleIterador < colLength_960) {
          this.arrayCol1_960.push(this.catalogs[i]);
          visibleIterador++;
        }
        else if (visibleIterador >= colLength_960 && visibleIterador < colLength_960 * 2) {
          this.arrayCol2_960.push(this.catalogs[i]);
          visibleIterador++;
        }
        else if (visibleIterador >= colLength_960 * 2 && visibleIterador < colLength_960 * 3) {
          this.arrayCol3_960.push(this.catalogs[i]);
          visibleIterador++;
        }
      }
    }
    this.arrayCol1_640 = [];
    this.arrayCol2_640 = [];
    const colLength_640 = Math.ceil(catalogsLength / 2);
    visibleIterador = 0;
    for (let i = 0; i < this.catalogs.length; i++) {
      if(this.catalogs[i].IsVisible){
        if (visibleIterador >= 0 && visibleIterador < colLength_640) {
          this.arrayCol1_640.push(this.catalogs[i]);
          visibleIterador++;
        }
        else if (visibleIterador >= colLength_640 && visibleIterador < colLength_640 * 2) {
          this.arrayCol2_640.push(this.catalogs[i]);
          visibleIterador++;
        }
      }
    }
    this.arrayCol1_320 = [];
    visibleIterador = 0;
    for (let i = 0; i < this.catalogs.length; i++) {
      if(this.catalogs[i].IsVisible){
        this.arrayCol1_320.push(this.catalogs[i]);
      }
    }
    this.filterAdvancedSearchOptions();
  }

  onFocus() {
    const inputElem = <HTMLInputElement>this.pageInput.nativeElement;
    inputElem.select();
  }

  stopPropagation(event, index = null) {
    event.stopPropagation();
    if(event.ctrlKey && index != null){
      this.selectTrack(null, index);
      return;
    }
  }

  cleanActualMusic(track, musics) {

    for (let j = musics.length - 1; j >= 0; j--) {
      const music = musics[j];
      if (music.id == track.id) {
        musics.splice(j, 1);
      }
    }

    return musics;
  }

  showSimilars(event, i, track) {
    event.stopPropagation();
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      this.checkTracksToSelect(this.getRealIndex(this.pagedTracks[i]));
      return;
    }
    if (!('isSimilar' in track) && !('isRelated' in track) && !('isFromAlbum' in track)) {
      event.stopPropagation();
      let pos = i;
      this.showTrackSimilar[i] = !this.showTrackSimilar[i];
      if (this.showTrackSimilar[i]) {
        let decrement = 0;
        for (let index = this.tracks.length-1; index >= 0; index--) {
          const element = this.tracks[index];
          if(('isRelated' in element) || ('isSimilar' in element) || ('isFromAlbum' in element)){
            this.tracks.splice(index, 1);
          } 
        }
        for (let j = this.pagedTracks.length - 1; j >= 0; j--) {
          if (('isRelated' in this.pagedTracks[j]) || ('isSimilar' in this.pagedTracks[j]) || ('isFromAlbum' in this.pagedTracks[j])) {
            this.pagedTracks.splice(j, 1);
            if (j < pos) {
              decrement++;
            }
          }
          if (j != i) {
            this.showTrackSimilar[j] = false;
          }
          this.showTrackInfo[j] = false;
          this.showTrackRelatedOrSimilar[j] = false;
          this.showAlbumTracks[j] = false;
        }
        pos -= decrement;
        this.loadingTracksSecondary = true;
        this.searchService.findMusicsSimilars(track.id, track.titleId).subscribe(data => {
          this.loadingTracksSecondary = false;
          let musics = this.musicService.mountMusicList(data, this.tonos, false, true, false, false);
          musics = this.cleanActualMusic(track, musics);
          musics.forEach(music => {
            music.IsVisible = true;
          });
          const start = pos + 1;
          this.pagedTracks.splice.apply(this.pagedTracks, [start, 0].concat(musics));
          const startTracks = pos +((this.trackPager.currentPage-1) * (this.authService.user.Pref_PageResults))+ 1;
          this.tracks.splice.apply(this.tracks, [startTracks, 0].concat(musics));
          this.updateMusics();
          if(this.dataService.getFPobject() != null){
            this.dataService.updateFPobjectTracks(this.tracks);
          }

          this.updateContainer();
          this.checkSelected();

        });
      } else {
        let idsToRemove = [];
        for (let id = pos + 1; id < this.pagedTracks.length; id++) {
          if (!('isRelated' in this.pagedTracks[id]) && !('isSimilar' in this.pagedTracks[id]) && !('isFromAlbum' in this.pagedTracks[id])) {
            break;
          } else {
            if (('isSimilar' in this.pagedTracks[id])) {
              this.showTrackInfo[id] = false;
              this.showTrackSimilar[id] = false;
              this.showAlbumTracks[id] = false;
              idsToRemove.push(id);
            }
          }
        }
        for (let j = idsToRemove.length - 1; j >= 0; j--) {
          this.pagedTracks.splice(idsToRemove[j], 1);
        }
        for (let index = this.tracks.length-1; index >= 0; index--) {
          const element = this.tracks[index];
          if(('isRelated' in element) || ('isSimilar' in element) || ('isFromAlbum' in element)){
            this.tracks.splice(index, 1);
          } 
        }
        this.updateMusics();
        if(this.dataService.getFPobject() != null){
          this.dataService.updateFPobjectTracks(this.tracks);
        }
        this.updateContainer();
        this.checkSelected();
      }
    }
  }

  showRelated(event, i, track) {
    if(event != undefined && event != null && event.ctrlKey){
      event.stopPropagation();
      this.selectTrack(null, i);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      this.checkTracksToSelect(this.getRealIndex(this.pagedTracks[i]));
      return;
    }
    this.wasDBL = true;
    if (window.getSelection) {window.getSelection().removeAllRanges();}
    setTimeout(() => {
      this.wasDBL = false;
    }, 500);
    if (!('isRelated' in track) && !('isSimilar' in track) && !('isFromAlbum' in track)) {
      event.stopPropagation();
      let pos = i;
      this.showTrackRelatedOrSimilar[i] = !this.showTrackRelatedOrSimilar[i];
      if (this.showTrackRelatedOrSimilar[i]) {
        let decrement = 0;
        for (let index = this.tracks.length-1; index >= 0; index--) {
          const element = this.tracks[index];
          if(('isRelated' in element) || ('isSimilar' in element) || ('isFromAlbum' in element)){
            this.tracks.splice(index, 1);
          } 
        }
        for (let j = this.pagedTracks.length - 1; j >= 0; j--) {
          if (('isRelated' in this.pagedTracks[j]) || ('isSimilar' in this.pagedTracks[j]) || ('isFromAlbum' in this.pagedTracks[j])) {
            this.pagedTracks.splice(j, 1);
            if (j < pos) {
              decrement++;
            }
          }
          if (j != i) {
            this.showTrackRelatedOrSimilar[j] = false;
          }
          this.showTrackInfo[j] = false;
          this.showTrackSimilar[j] = false;
          this.showAlbumTracks[j] = false;
        }
        pos -= decrement;
        this.loadingTracksSecondary = true;
        this.searchService.findMusicsRelated(track.id).subscribe(data => {
          this.loadingTracksSecondary = false;
          let musics = this.musicService.mountMusicList(data, this.tonos, true, false, false, false);
          musics = this.cleanActualMusic(track, musics);
          const start = pos + 1;
          musics.forEach(music => {
            music.IsVisible = true;
          });
          this.pagedTracks.splice.apply(this.pagedTracks, [start, 0].concat(musics));
          const startTracks = pos +((this.trackPager.currentPage-1) * (this.authService.user.Pref_PageResults))+ 1;
          this.tracks.splice.apply(this.tracks, [startTracks, 0].concat(musics));
          this.updateMusics();
          if(this.dataService.getFPobject() != null){
            this.dataService.updateFPobjectTracks(this.tracks);
          }
          this.updateContainer();
          this.checkSelected();
        });
      } else {
        let idsToRemove = [];
        for (let id = pos + 1; id < this.pagedTracks.length; id++) {
          if (!('isRelated' in this.pagedTracks[id]) && !('isSimilar' in this.pagedTracks[id]) && !('isFromAlbum' in this.pagedTracks[id])) {
            break;
          } else {
            if (('isRelated' in this.pagedTracks[id])) {
              this.showTrackInfo[id] = false;
              this.showTrackSimilar[id] = false;
              this.showAlbumTracks[id] = false;
              idsToRemove.push(id);
            }
          }
        }
        for (let j = idsToRemove.length - 1; j >= 0; j--) {
          this.pagedTracks.splice(idsToRemove[j], 1);
        }
        for (let index = this.tracks.length-1; index >= 0; index--) {
          const element = this.tracks[index];
          if(('isRelated' in element) || ('isSimilar' in element) || ('isFromAlbum' in element)){
            this.tracks.splice(index, 1);
          } 
        }
        this.updateMusics();
        if(this.dataService.getFPobject() != null){
          this.dataService.updateFPobjectTracks(this.tracks);
        }
        this.updateContainer();
        this.checkSelected();
      }
    }
  }

  updateContainer() {
    let isSomethingPlaying = false;
    for (let id = 0; id < this.pagedTracks.length; id++) {
      this.pagedTracks[id].container = '#track-' + id + '-' + this.pagedTracks[id].metadataID + '-progress';
      if (this.pagedTracks[id].isPlaying) {
        this.indexGlobal = id;
        isSomethingPlaying = true;
      }
    }
    this.dataService.updateContainer({ tracks: this.pagedTracks });
    if (!isSomethingPlaying) {
      this.indexGlobal = null;
    }
      this.tracks.forEach((track, it) => {
        let containerIt = it;
        if(containerIt >= this.authService.user.Pref_PageResults){
          containerIt-=this.authService.user.Pref_PageResults;
        }
        const width = track.currentTime * 100 / track.durationSecs;
        if(width != 0 && width != 100){
          $('#track-' + containerIt + '-' + track.metadataID + '-progress').css({
            width: width + '%',
            borderRight: '1px solid black'
          });
        }
      });

  }

  checkSelected() {
    this.tracks.forEach(track => {
      track.isTrackSelected = false;
      this.playerHistory.selectedSongsCATALOGS.forEach(selectedTrack => {
        if (track.id == selectedTrack.id) {
          track.isTrackSelected = true;
        }
      });
    });
  }

  checkIfDeleteChipFromSearchBar() {
    const el = document.getElementById('row-one') as HTMLInputElement;
    const value = el.value;
    if (value.length == 0) {
      if (this.chips.length > 0) {
        const tempChip = this.chips[this.chips.length - 1];
        const text = tempChip.name + " ";
        this.deleteChip(tempChip);
        el.value = text;
      }
    }
  }

  addChipInAbsolutePosition(event, i) {
    event.stopPropagation();
    const el = document.getElementById('secondary-input' + i) as HTMLInputElement;
    const value = el.innerText;
    el.innerText = "";
    el.style.width = "5px";
    if (value.trim() != "") {
      this.addChip(value.replace(new RegExp('_', 'g'), ' '), i);
    }
  }

  checkWhatCatalogIsSelected() {
    if (this.showCatalogsDDL) {
      setTimeout(() => {
        const idToGo = "catalogItem-" + this.selectedCatalogPosition;
        const offset = $('#' + idToGo).offset();
        if (offset === undefined) {
          return;
        }
        const positionTop = $('#' + idToGo).offset().top - 285;
        $('#' + idToGo).css("background", "#676767");
        $('#' + idToGo + ' h3').css("color", "#f2f2f2");
        let top = positionTop - ($("#catalogsDDL").height() / 2) + $(".albunDDL-unit").height();
        if (top < 0) {
          top = 0;
        }
        $('#catalogsDDL').scrollTop(top);
      }, 100);
    }
  }

  checkWhatAlbumIsSelected() {
    if (this.showAlbunsDDL) {
      setTimeout(() => {
        const idToGo = "albumItem-" + this.selectedAlbumPosition;
        const offset = $('#' + idToGo).offset();
        if (offset === undefined) {
          return;
        }
        const positionTop = $('#' + idToGo).offset().top - 285;
        $('#' + idToGo).css("background", "#676767");
        $('#' + idToGo + ' h3').css("color", "#f2f2f2");
        let top = positionTop - ($("#albunsDDL").height() / 2) + $(".albunDDL-unit").height();
        if (top < 0) {
          top = 0;
        }
        $('#albunsDDL').scrollTop(top);
      }, 100);
    }
  }

  navigateBefore() {
    if (!$('#row-one').is(':focus') && !this.modalOpen && !$('input').is(':focus')) {
      const url = window.location.href;
      const string = url.split('catalogos')[1];
      this.chips = [];
      this.secondaryInputs = [];
      if (string != undefined && string.trim() != '') {
        const array = string.split('/');
        if (array.length == 2) {
          this.selectedCatalogPosition = 1;
          this.selectedCatalog = this.catalogs[this.selectedCatalogPosition];
          this.anyCatalogSelected = false;
          this.loadingAlbuns = false;
          this.location.go('./catalogos');
          // this.router.navigate(['catalogos']);
          this.actualURL = window.location.href;
          this.search(null);
          this.selectedCountryPosition = 0;
          this.selectedFocusPosition = 0;
          this.selectedProducerPosition = 0;
          this.selectedReleasePosition = 0;
        } else {
          this.location.go('./catalogos/' + this.selectedCatalog.Context1J);
          // this.router.navigate(['catalogos/' + this.selectedCatalog.Context1J]);
          this.actualURL = window.location.href;
          this.selectedAlbumPosition = 0;
          this.anyAlbunSelected = false;
          let searchObj = {
            lvl: 2,
            chips:"",
            catalog: this.selectedCatalog,
            index: this.selectedCatalogPosition
          };
          this.saveState.setLastSearchChipsCatalogs(searchObj);
          this.saveState.setLastFindCatalogsTab(this.catalogs);
          
          if (!this.albuns[1].hasOwnProperty('cover')) {
            this.getAlbunsOfCatalog();
          }
        }
      }
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.wentToInit = false; 
    this.saveState.setLastUrlCatalog(this.actualURL);
    this.resetCatalogs();
    this.dataService.setInitialCatalogsGroups(this.catalogs);
    setTimeout(() => {
      if(!this.wentToInit){
        this.checkURL(0);
      }
    }, 10);
  }

  resetCatalogs(){
    this.catalogs.forEach((catalog, it) => {
      if(it!=0){
        catalog.IsVisible = true;
      }
    });
  }


  updateChipValue(id, chip) {
    event.preventDefault();
    const texto = $("#" + id).text();
    chip.name = texto;
  }

  openPlayerExtras(index, event) {
    event.stopPropagation();
    for (let i = 0, len = this.tracks.length; i < len; i++) {
      this.isPlayerExtras[i] = false;
    }
    this.isPlayerExtras[index] = true;
  }

  checkPaddingTopWF(track) {
    if (!track.isloadToWave) {
      return '0px';
    } else {
      const windowWidth = $(window).width() + 17;
      if (windowWidth < 640) {
        return '11px';
      }
      else if (windowWidth >= 640 && windowWidth < 960) {
        return '11px';
      }
      else if (windowWidth >= 960 && windowWidth < 1440) {
        return '5px';
      }
      else if (windowWidth >= 1440 && windowWidth < 1920) {
        return '0px';
      }
      else {
        return '0px';
      }
    }
  }

  setZIndex(id) {
    if ($('.track-download-dropdown').is(":visible") || $('.track-projects-dropdown').is(":visible") || $('.track-actions-mobile').is(":visible")) {

    } else {
      $(".track").css("z-index", "1");
      $("#" + id).css("z-index", "12");
    }
  }

  setZIndexForce(id,  event, i) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      this.checkTracksToSelect(this.getRealIndex(this.pagedTracks[i]));
      return;
    }
    $(".track").css("z-index", "1");
    $("#" + id).css("z-index", "12");
  }

  checkClassForCheckAll(){
    let anySelected = false;
    let allSelected = true;
    for (let it = 0; it < this.pagedTracks.length; it++) {
      const track = this.pagedTracks[it];
      if (!('isRelated' in track) && !('isSimilar' in track) && !('isFromAlbum' in track)){
        if(track.isTrackSelected){
          anySelected =  true;
        } else {
          allSelected = false;
        }
      }
    }
    if(allSelected && this.pagedTracks.length>0){
      return 'invertColor';
    } else if(anySelected && this.pagedTracks.length>0){
      return 'fullOpacity';
    } else{
      return '';
    }
  }

  checkIfFreezePlaying(){
    if(window['globalWavesurfer'].isPlaying()){
      return true;
    } else {
      return false;
    }
  }
  
  getRealIndex(track){
    let id = null;
    for (let it = 0; it < this.tracks.length; it++) {
      const tempTrack = this.tracks[it];
      if(('isRelated' in track) || ('isSimilar' in track) || ('isFromAlbum' in track)){
        if(track.id == tempTrack.id && (('isRelated' in tempTrack) || ('isSimilar' in tempTrack) || ('isFromAlbum' in tempTrack))){
          id = it;
          break;
        }
      } else {
        if(track.id == tempTrack.id && !('isRelated' in tempTrack) && !('isSimilar' in tempTrack) && !('isFromAlbum' in tempTrack)){
          id = it;
          break;
        }
      }
    }
    return id;
  }

  checkIfAlreadyPlayed(track){
    if(track.alreadyPlayed && $(track.timeContainer).text().trim() == "00:00" && this.dataService.compareWithSelected(track)){
      return 'alreadyPlayed';
    }
    return '';
  }

  get_catalog_container_class(){
    if(!this.anyCatalogSelected){
      if(this.isAddRemoveLine){
        return 'cat_adv_aberto';
      } else {
        return 'cat_adv_fechado';
      }
    } else if(this.anyCatalogSelected && !this.anyAlbunSelected){
      return 'cat_album';
    } else {
      return 'cat_album_traks';
    }

  }
}
