import { Component, OnInit, OnChanges } from '@angular/core';
import { Album } from '../album';
import { AlbumService } from '../album.service';
import { AuthService } from '../auth/auth.service';
import { I18nGlvService } from '../i18n-glv.service';
import { UserService } from '../user.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { HomeinfoService } from '../homeinfo.service';
import { PagerService } from '../pager.service';
import { SaveStateService } from '../save-state.service';


@Component({
  selector: 'app-home-albums',
  templateUrl: './home-albums.component.html',
  styleUrls: ['./home-albums.component.scss']
})
export class HomeAlbumsComponent implements OnInit {

  albums: Album[];
  loadingAlbuns = true;

  pagedAlbuns = [];
  AlbunsPager: any = {};
  AlbunsPage = 1;
  AlbunsPerPage = 10;

  constructor(private albumService: AlbumService, public authService: AuthService, private homeInfo: HomeinfoService,
    public saveState: SaveStateService,  private userService: UserService, public i18n: I18nGlvService, private pagerService: PagerService, public router: Router, private dataService: DataService) {

  }

  ngOnInit() {
    this.getAlbums();
  }

  getAlbums(): void {
    const tempAlbuns = this.homeInfo.getHomeAlbuns();
    if (tempAlbuns == null) {
      this.albumService.getAlbums().subscribe(albums => {
        this.loadingAlbuns = false;
        const idioma = this.i18n.language;
        let path = '';
        let albumslist = [];
        switch (idioma) {
          case 'pt':
          case 'es':
          case 'en':
            path = albums[idioma].path + '/';
            albumslist = albums[idioma].albuns;
            break;
          default:
            path = albums['en'].path + '/';
            albumslist = albums['en'].albuns;
            break;
        }
        const listalbuns = [];
        for (let index = 0; index < albumslist.length; index++) {
          const element = albumslist[index];
          listalbuns[index] = {
            'id': index,
            'name': element.name,
            'description': element.description,
            'reference': element.code,
            'thumb': environment.baseUrl + 'image/album/' + element.catalog.replace(new RegExp(' & ', 'g'), '').replace('+','~').replace(new RegExp(' ', 'g'), '_') +
              '/' + element.code.replace(new RegExp(' & ', 'g'), '').replace(new RegExp(' ', 'g'), '_') + '/?&token=' + this.authService.user.token + '&username=' + this.authService.user.username,
            'showHome': true,
            'J': element.J,
            'K': element.K,
            'catalog': element.catalog,
            'externalLink': element.external_link
          };
        }
        this.albums = listalbuns;
        this.homeInfo.updateHomeAlbuns(this.albums);
        this.setAlbunsPage(1);
      });
    } else {
      this.albums = tempAlbuns;
      this.loadingAlbuns = false;
      this.setAlbunsPage(1);
    }
  }

  setAlbunsPage(page) {
    if (isNaN(page)) {
      return;
    }
    if (page < 1) {
      page = 1;
    }
    if (page > this.AlbunsPager.totalPages) {
      page = this.AlbunsPager.totalPages;
    }
    // get pager object from service
    if (this.albums) {
      this.AlbunsPager = this.pagerService.getPager(this.albums.length, page, this.AlbunsPerPage);
    }

    // get current page of items
    if (this.albums) {
      this.pagedAlbuns = this.albums.slice(this.AlbunsPager.startIndex, this.AlbunsPager.endIndex + 1);
    }
    this.AlbunsPage = +this.AlbunsPager.currentPage; // + Cast String to Number
  }

  // filterAlbums(albums) {
  //   return albums.showHome === true;
  // }

  goToCatalog(album) {
	// PG: 2025/01/23
	this.saveState.removelastSearchCatalogs();
	this.dataService.updateSelectedAlbum(album);
	debugger;
	this.router.navigate(['/catalogos/'+album.J+'/'+album.K]);
	return;
	}

  parseInteger(string) {
    return parseInt(string, 10);
  }
}
